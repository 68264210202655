import ourStoryVideo from '../../assets/videos/ourStoryVideo.MP4'

const OurStory = () => {
  return (
    <div className='our-story bg-gray-light flex items-center space-x-20'>
      <div className="our-story-content pl-24">
        <h2 className='title'>Our <span className='title-red'>Story</span></h2>
        <p className='font-light'>
        A war torn country where almost every building was demolished and there were no building standards… SBGroup took the initiative and worked hard to re-build Afghanistan with quality workmanship and a capable and safe workforce. We at SBGroup  seek opportunities to leave a legacy when we place each brick.
        </p>
      </div>
      <div className="our-story-video">
        <video src={ourStoryVideo} controls>
          <source src={ourStoryVideo} type='video/mp4'/>
        </video>
      </div>
    </div>
  )
}

export default OurStory