import { HashLink } from "react-router-hash-link";
import heroAbout from "../../assets/Images/about/HeroAbout-2.jpg";

const Hero = () => {
  return (
    <div className="about-hero">
      <img src={heroAbout} alt="main about us" className="hero-image" />
      <div className="hero-content">
        <h2 className="font-bold">
          BUILDING FUTURE <br /> TOGETHER
        </h2>
        <p className="hero-paragraph font-light">
          SBGroup has built a unique status among its valued clients. Our expert
          and capable staff provide professional services in consultancy, survey
          & design, procurement, logistics, construction and maintenance with
          high quality and assurance. SBGroup works closely with its partners,
          contractors, suppliers, sub-contractors, and operators to ensure
          clients expectations are met. SBGroup displays an exceptional
          commitment to lead projects and mitigate risks.
        </p>
        <HashLink to={"/about#our-team"}>
          <button className="btn-red mt-7">Our team</button>
        </HashLink>
      </div>
    </div>
  );
};

export default Hero;
