import SBGlogoWhite from "../../../assets/logos/SBG-white.png";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
const DFooter = () => {
  let iconStyle = { color: "white", fontSize: "1.2em" };
  return (
    <div className="bg-black footer flex pt-14 pl-24 justify-between pr-24">
      <div className="section-1">
        <div className="logo-section mb-9">
          <img src={SBGlogoWhite} alt="SBGroup logo" />
        </div>
        <div className="social-media-section text-white flex-col">
          <h2 className="text-lg mb-4">Our Social Media Links</h2>
          <div className="social-icons flex space-x-4">
            <a href="https://m.facebook.com/profile.php?id=100063718116264" target="_blank" rel="noreferrer">
              <FaFacebookF style={iconStyle} />
            </a>
            <a href="https://www.linkedin.com/in/sadat-bg-99590911a/" target="_blank" rel="noreferrer">
              <FaLinkedin style={iconStyle} />
            </a>
            <a href="https://twitter.com/SADATBUSINESSG" target="_blank" rel="noreferrer">
              <FaTwitter style={iconStyle} />
            </a>
            <a href="https://www.instagram.com/sadathbusinessgroupptyltd/" target="_blank" rel="noreferrer">
              <FaInstagram style={iconStyle} />
            </a>
          </div>
        </div>
      </div>

      <div className="section-2 flex text-white space-x-24 pr-56">
        <div className="link-section">
          <h2>Who We Are</h2>
          <ul>
            <HashLink to={"/about#about-us"}>
              <li>
                <h2>• About us</h2>
              </li>
            </HashLink>
            <HashLink to={"/about#our-leadership"}>
              <li>
                <h2>• Our leadership</h2>
              </li>
            </HashLink>
            <HashLink to={"/#what-we-do"}>
              <li>
                <h2>• What we do</h2>
              </li>
            </HashLink>
            <HashLink to={"/about#meet-team"}>
              <li>
                <h2>• Meet the team</h2>
              </li>
            </HashLink>
          </ul>
        </div>
        <div className="link-section">
          <h2>Projects</h2>
          <ul>
            <HashLink to={"/services#hash-service-footer"}>
              <li>
                <h2>• Our services</h2>
              </li>
            </HashLink>
            <HashLink to={"/#hash-experince-footer"}>
              <li>
                <h2>• Our Experiences</h2>
              </li>
            </HashLink>
            <HashLink to={"/capabilities#our-work-hash"}>
              <li>
                <h2>• Capabilities</h2>
              </li>
            </HashLink>
            <HashLink to={"/services#hash-water"}>
              <li>
                <h2>• Water crisis</h2>
              </li>
            </HashLink>
          </ul>
        </div>
        <div className="link-section">
          <h2>Other Links</h2>
          <ul>
            {/* <li>
              <h2>• Helping people</h2>
            </li> */}
            <HashLink to={"/about#hash-partner"}>
              <li>
                <h2>• Our partners</h2>
              </li>
            </HashLink>
            <HashLink to={"/services#hash-eco"}>
              <li>
                <h2>• Renewable Energy</h2>
              </li>
            </HashLink>
            <HashLink to={"/gallery#hash-gallery"}>
              <li>
                <h2>• Work show case</h2>
              </li>
            </HashLink>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DFooter;
