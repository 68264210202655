import '../../style/about.css'
import DAbout from './DAbout'
import MAbout from './MAbout'

const About = () => {
  return (
    <div className='about-us'>
      <div className="desktop-size">
      <DAbout/>
      </div>
      <div className="mobile-size md:invisible">
      <MAbout/>
      </div>
    </div>
  )
}

export default About