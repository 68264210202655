import { Link } from "react-router-dom";

const SuccessMsg = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center text-center">
      <div className="successfull-message flex flex-col space-y-14 p-16 pl-24 pr-24">
        <h2 className="text-2xl text-green">Email Successfully Sent?</h2>
        <p className="font-light">
          Thank you for message, we will get back to you soon.
        </p>
        <Link to={"/"} className='p-3 bg-gray-light'>
          <h2 className="font-medium">Go to Home Page</h2>
        </Link>
      </div>
    </div>
  );
};

export default SuccessMsg;
