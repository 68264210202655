import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import SBGcolor from "../../../assets/logos/SBG-color.png";
import { useEffect } from "react";
const DNavbar = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {}, [show]);
  const location = useLocation();
  const pathMatch = (path) => {
    if (path === location.pathname) {
      return location;
    }
  };

  return (
    //  navbar flex items-center justify-between p-5 shadow-md
    <nav className="fixed top-0 left-0 bg-white w-full shadow z-50">
      <div className="container flex justify-between items-center">
        <div className="max-[700px]:pl-4 pl-24 py-4">
          <Link
            to="/"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={pathMatch("/") ? "font-medium text-red" : "font-light"}
          >
            <img
              src={SBGcolor}
              alt="SBGroup-logo"
              className="max-[700px]:h-5 h-7"
            />
          </Link>
        </div>
        <div className="hidden md:flex nav-links space-x-12 text-sm">
          <Link
            to="/"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={pathMatch("/") ? " font-medium text-red" : "font-light"}
          >
            Home
          </Link>
          <Link
            to="/about"
            id="scroll-top"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            className={
              pathMatch("/about") ? " font-medium text-red" : "font-light"
            }
          >
            About us
          </Link>
          <Link
            to="/services"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={
              pathMatch("/services") ? "font-medium text-red" : "font-light"
            }
          >
            Services
          </Link>
          <Link
            to="/capabilities"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={
              pathMatch("/capabilities") ? "font-medium text-red" : "font-light"
            }
          >
            Capabilities
          </Link>
          {/* <Link
          to="/responsabilities"
          className={
            pathMatch("/responsabilities")
              ? "font-medium text-red"
              : "font-light"
          }
        >
          Responsabilities
        </Link> */}
          <Link
            to="/gallery"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={
              pathMatch("/gallery") ? "font-medium text-red" : "font-light"
            }
          >
            Gallery
          </Link>
          <Link
            to="/contact-us"
            onClick={() => {
              window.scroll({ top: 0 });
            }}
            id="scroll-top"
            className={
              pathMatch("/contact-us") ? "font-medium text-red" : "font-light"
            }
          >
            Contact
          </Link>
        </div>
        <button
          className="block md:hidden mx-2 py-3 px-4 rounded focus:ouline-none group"
          onClick={() => setShow(!show)}
        >
          {!show ? (
            <>
              <div className="w-5 h-1 bg-black mb-1"></div>
              <div className="w-5 h-1 bg-black mb-1"></div>
              <div className="w-5 h-1 bg-black"></div>
            </>
          ) : (
            <>
              <FaTimes size={"1.5em"} />
            </>
          )}
        </button>
        {show ? (
          <div className="absolute top-0 bg-white md:invisible h-screen w-[80vw] shadow transition-all duration-300">
            <ul className="flex flex-col items-center pt-10 w-full cursor-pointer mt-10">
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-10"
              >
                <Link
                  to="/"
                  className="py-4 px-6 w-full"
                  onClick={() => setShow(!show)}
                >
                  Home
                </Link>
              </span>
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-16"
              >
                <Link
                  to="/about"
                  className="hover:bg-gray-light py-4 px-6 w-full nav-link-click"
                  onClick={() => setShow(!show)}
                >
                  About
                </Link>
              </span>
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-10"
              >
                <Link
                  to="/services"
                  className="hover:bg-gray-light py-4 px-6 w-full nav-link-click"
                  onClick={() => setShow(!show)}
                >
                  Services
                </Link>
              </span>
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-10"
              >
                <Link
                  to="/capabilities"
                  className="hover:bg-gray-light py-4 px-6 w-full nav-link-click"
                  onClick={() => setShow(!show)}
                >
                  Capabilities
                </Link>
              </span>
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-10"
              >
                <Link
                  to="/gallery"
                  className="hover:bg-gray-light py-4 px-6 w-full nav-link-click"
                  onClick={() => setShow(!show)}
                >
                  Gallery
                </Link>
              </span>
              <span
                id="scroll-top-view"
                onClick={() => {
                  window.scroll({ top: 0 });
                }}
                className="mb-10"
              >
                <Link
                  to="/contact-us"
                  className="hover:bg-gray-light py-4 px-6 w-full nav-link-click"
                  onClick={() => setShow(!show)}
                >
                  Contact us
                </Link>
              </span>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
};

export default DNavbar;
