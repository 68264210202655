import { v4 as uuid } from "uuid"
import partner1 from '../../assets/logos/NUClogo.png'
import partner2 from '../../assets/Images/about/partner2.jpg'
import partner3 from '../../assets/logos/LucasLogo.jpg'
const ourPartnersData = [
  {
    id: uuid(),
    img: partner1,
    link: 'https://google.com'
  },
  {
    id: uuid(),
    img: partner2,
    link: 'https://google.com'
  },
  {
    id: uuid(),
    img: partner3,
    link: 'https://lucask9.com/'
  },
]

export default ourPartnersData