import React from 'react'
import Form from '../../components/contact/Form'
import HeroContact from '../../components/contact/HeroContact'

const DContact = () => {
  return (
    <div>
      <HeroContact/>
      <Form/>
    </div>
  )
}

export default DContact