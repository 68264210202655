import "../../style/home.css";
import DHome from "./DHome";
import MHome from "./MHome";

const Home = () => {
  return (
    <div>
      <div className="desktop-size">
        <DHome />
      </div>
      <div className="mobile-size">
        <MHome />
      </div>
    </div>
  );
};

export default Home;
