import heroBanner from "../../assets/Images/contact/HeroBanner.jpg";
import officesData from "../../data/contact/offices";
import { FaLocationArrow, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
const HeroContact = () => {
  return (
    <div className="contact-main flex justify-between items-center">
      <div className="banner-img mr-32">
        <img src={heroBanner} alt="" />
      </div>
      <div className="banner-content pr-24">
        <h2 className="title mb-2">
          Our <span className="text-green">Offices</span>
        </h2>
        <p className="font-light">
          Please feel free to contact us by sending us your feedback through
          filling the form or simply dial our phone no. And we will get back to
          you as soon as possible.
        </p>
        <ul className="office-box grid grid-cols-2 gap-16 mt-10">
          {officesData.map((office) => {
            return (
              <li key={office.id}>
                <h2 className="text-2xl font-medium mb-8">{office.country}</h2>
                <div className="contact-box flex justify-start space-x-7 mb-7 h-9">
                  <FaLocationArrow size={"1rem"} />
                  <a
                    href={office.map}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-gray"
                  >
                    <h2 className="font-light text-sm">{office.address}</h2>
                  </a>
                </div>
                {/* phone */}
                <div className="contact-box flex justify-start space-x-7 mb-7 h-9">
                  <FaPhoneAlt size={"1rem"} />
                  <a href={"tel:" + office.contact} className="hover:text-gray">
                    <h2 className="font-light text-sm">{office.contact}</h2>
                  </a>
                </div>
                {/* email */}
                <div className="contact-box flex justify-start space-x-7">
                  <FaEnvelope size={"1rem"} />
                  <a
                    href={"mailto:" + office.email}
                    className="hover:text-gray"
                  >
                    <h2 className="font-light text-sm">{office.email}</h2>
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default HeroContact;
