import logisticData from "../../data/servicesData/logistics"
const Procurement = () => {
  return (
    <div className='pl-52 pr-52' id="hash-procurement">
      <div className="procurment-main-section text-center mb-10">
        <h2 className="title">Procurement & <span className="text-green">Supply</span></h2>
      </div>
      <div className="logistic-solution">
        <h2 className="font-medium text-lg mb-4">Logistic solutions</h2>
        <p className="font-light">Over the progression of decades, SBGroup has earned a reputation for delivering an exceptional customer experience and complete customer satisfaction on every task order. We offer a full range of international and domestic transportation services via air, ocean, and ground around the globe, No matter the size of the task nor the target, you can be confident we'll deliver.</p>
      </div>

      <ul className="grid grid-cols-3 gap-16 mt-10" id="hash-logistics">
        {
          logisticData.map(data => {
            return(
              <li key={data.id} className='shadow-md pb-6'>
                <img src={data.image} alt="logistic data" className="mb-5"/>
                <h2 className="font-medium p-2 pb-4">{data.title}</h2>
                <p className="font-light text-xs p-2">
                  {data.description}
                </p>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Procurement