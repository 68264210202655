import waterSectorImg from "../../assets/Images/services/waterSector.jpg";
const WaterSector = () => {
  return (
    <div className="water-sector-container text-center" id="hash-water">
      <h2 className="title">
        Water <span className="title-red">Sector</span>
      </h2>
      <div className="water-sector-img">
        <img src={waterSectorImg} alt="" />
        <div className="water-section-content-hero">
          <h2 className="title-white mb-6">Solving the Water Crisis</h2>
          <p className="text-white font-light text-xl">
            Accelerating Water Sector Transformation{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WaterSector;
