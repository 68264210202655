import Construction from '../../components/services/Construction'
import DrTimBio from '../../components/services/DrTimBio'
// import Engineering from '../../components/services/Engineering'
import HeroContent from '../../components/services/HeroContent'
import HeroServices from '../../components/services/HeroServices'
import K9 from '../../components/services/K9'
// import IndustrySector from '../../components/services/IndustrySector'
import MaterialTest from '../../components/services/MaterialTest'
import PEB from '../../components/services/PEB'
import Procurement from '../../components/services/Procurement'
import Recruitment from '../../components/services/Recruitment'
import RegionWeWork from '../../components/services/RegionWeWork'
import RenewableEnergy from '../../components/services/RenewableEnergy'
import RenewableProjects from '../../components/services/RenewableProjects'
// import RenewableServices from '../../components/services/RenewableServices'
import WaterProjects from '../../components/services/WaterProjects'
import WaterSector from '../../components/services/WaterSector'

const DServices = () => {
  return (
    <div >
      <HeroServices/>
      <HeroContent/>
      <div className="section-break"></div>
      <RegionWeWork/>
      {/* <div className="section-break"></div> */}
      {/* <Engineering/> */}
      <Construction/>
      <div className="section-break"></div>
      <WaterSector/>
      <div className="section-break"></div>
      <DrTimBio/>
      <div className="section-break"></div>
      <WaterProjects/>
      <div className="section-break"></div>
      <RenewableEnergy/>
      <div className="section-break"></div>
      {/* <RenewableServices/>
      <div className="section-break"></div> */}
      <RenewableProjects/>
      <div className="section-break"></div>
      <Recruitment/>
      <div className="section-break"></div>
      <Procurement/>
      <div className="section-break"></div>
      {/* <IndustrySector/>
      <div className="section-break"></div> */}
      <MaterialTest/>
      <div className="section-break"></div>
      <K9/>
      <div className="section-break"></div>
      <PEB/>
      <div className="section-break"></div>

    </div>
  )
}

export default DServices