import React from 'react'
import Project1 from '../../components/gallery/projects/Project1'
import Project2 from '../../components/gallery/projects/Project2'
import Project3 from '../../components/gallery/projects/Project3'
import Project4 from '../../components/gallery/projects/Project4'
import Project5 from '../../components/gallery/projects/Project5'
import Project6 from '../../components/gallery/projects/Project6'
import Project7 from '../../components/gallery/projects/Project7'
import Project8 from '../../components/gallery/projects/Project8'
import Project9 from '../../components/gallery/projects/Project9'
import GalleryBanner from '../../components/gallery/GalleryBanner'
import ProjectsGallery from '../../components/gallery/ProjectsGallery'

const DGallery = () => {
  return (
    <div id='hash-gallery'>
      <GalleryBanner/>
      <div className="section-break"></div>
      <ProjectsGallery/>
      <Project1 />
      <Project2/>
      <Project3/>
      <Project4/>
      <Project5/>
      <Project6/>
      <Project7/>
      <Project8/>
      <Project9/>
    </div>
  )
}

export default DGallery