import heroAbout from "../../assets/Images/about/HeroAbout-2.jpg";
import ceoImg from "../../assets/Images/about/ceo-img.jpg";
import leadershipMembers from "../../data/team/leaderships";
import ourPartnersData from "../../data/about/ourPartners";
import workedWithData from "../../data/about/workedWith";
const MAbout = () => {
  return (
    <div className="overflow-y-hidden">
      {/* Hero picture */}
      <div className="" id="about-us">
        <img
          src={heroAbout}
          alt="main about us"
          className="h-[30em] object-cover object-left"
        />
        <div className="p-4">
          <h2 className="font-medium mb-4 text-2xl">
            BUILDING FUTURE TOGETHER
          </h2>
          <p className=" font-light">
            SBGroup has built a unique status among its valued clients. Our
            expert and capable staff provide professional services in
            consultancy, survey & design, procurement, logistics, construction
            and maintenance with high quality and assurance. SBGroup works
            closely with its partners, contractors, suppliers, sub-contractors,
            and operators to ensure clients expectations are met. SBGroup
            displays an exceptional commitment to lead projects and mitigate
            risks.
          </p>
        </div>
      </div>
      {/* CEO image */}
      <div className="flex flex-col items-center mt-10" id="our-leadership">
        <img
          src={ceoImg}
          alt="ceo-pic"
          className="h-[14em] w-[14em] object-cover rounded-full"
        />
        <div className="mb-3 p-4 text-center">
          <h2 className="font-medium text-lg mb-3">Sayed Ishaq Sadath</h2>
          <p className="mb-5 text-green font-light">Founder & CEO</p>
          <p className="font-light">
          We are committed to deliver quality service that meets the
          expectations of our valued clients. SBGroup delivers projects that
          demand more from the contractor than average. We are hard working,
          easy to talk to, and we love challenges.
        </p>
        <br />
        <span className="mt-2 font-light">
          <p>
            Mr Sadath is also president od Sadath Foundation Inc., a charity
            established to work in emergency relief, in the water sector, and in
            the disability services sector
          </p>
          <p className="mt-2">contact via: </p>
          <a className="text-green" href="mailto:foundation@sadatbg.com">
            foundation@sadatbg.com
          </a>
        </span>
        </div>
      </div>
      {/*  Our Team */}
      <div className="flex-col text-center mt-10" id="meet-team">
        <h2 className="title mb-5">
          Our
          <span className="title-red" id="our-team">
            Leadership
          </span>
          team
        </h2>
        <div className="">
          <ul className="flex">
            {leadershipMembers.map((member) => {
              return (
                <li
                  key={member.id}
                  className="flex-col flex items-center p-2"
                >
                  <img
                    src={member.image}
                    alt="leader pic"
                    className="h-16 w-16 bg-cover "
                  />
                  <div className="flex items-center flex-col">
                    <h2 className="font-medium text-xs pt-2">{member.name}</h2>
                    <p className="font-light text-xs pt-2">{member.position}</p>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="background-grad"></div>
        </div>
      </div>
      {/* Partners  */}
      <div className="text-center  mt-24" id="hash-partner">
        <h2 className="title mb-10">
          Our <span className="title-red">Partners</span>
        </h2>
        <ul className="flex items-center justify-center">
          {ourPartnersData.map((partner) => {
            return (
              <li
                key={partner.id}
                className="flex items-center space-x-20 justify-center h-[6em] w-[6em]"
              >
                <a href={partner.link} target="_blank" rel="noreferrer">
                  <img
                    src={partner.img}
                    alt=""
                    className=" bg-contain h-[3em] w-[3em]"
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {/* Worked with */}
      <div className="text-center">
        <h2 className="title pt-8 mb-4">
          Organizations <span className="title-red">We Worked</span> With{" "}
        </h2>
        <p className="font-light text-gray mb-12">
          We worked with more than 10+ organizations some of them are mentioned
          below
        </p>
        <ul className="grid grid-cols-2 grid-row-7 gap-10 pl-6">
          {workedWithData.map((data) => {
            return (
              <li
                key={data.id}
                className=" h-[6em] w-[7em] shadow-sm flex items-center justify-center"
              >
                <a href={data.link} target="_blank" rel="noreferrer">
                  <img
                    src={data.logo}
                    alt="data logo"
                    className="h-[3.4em] w-[4.2em] bg-cover"
                  />
                </a>
                <div className="worked-with-description">
                  {/* <p className='invisible'>{data.description}</p> */}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MAbout;
