import ourMissionData from "../../data/about/ourMission";

const OurMission = () => {
  return (
    <div className="flex-col flex justify-center items-center ">
      <h2 className="title">
        Our <span className="title-red">Mission</span>
      </h2>
      <div className="our-mission-data pl-24 pr-24">
        <ul className="grid grid-cols-3 mt-5 gap-4">
          {ourMissionData.map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col items-center"
              >
                <div className="pic-box">
                  <h2>{data.title}</h2>
                  <img src={data.img} alt="" className="mission-pic" />
                </div>
                <p
                  className=" font-light text-xs text-gray-dark
                  mt-5 text-justify p-2 leading-4"
                >
                  {data.caption}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OurMission;