import materialTestData from "../../data/servicesData/materialTestData";

const MaterialTest = () => {
  return (
    <div className="pl-52 pr-52" id="hash-testing">
      <div className="material-test-main-section text-center mb-5">
        <h2 className="title pb-4">
          Material Test <span className="text-green">Laboratory</span>
        </h2>
        <p className="font-light">
          For the sake of better project implementation and quality work,
          SBGroup have established equipped laboratory. We proudly test your A2Z
          Concrete, Asphalt, Soil etc for your construction projects. Our Lab is
          USACE certified.
        </p>
      </div>
      <div className="material-test-lists">
        <ul className="pl-40 pr-40">
          {materialTestData.map((test) => {
            return (
              <li
                key={test.id}
                className="test-material-list flex mt-12 items-center"
              >
                <div className="image-section pr-8 pl-8">
                  <img src={test.image} alt="" />
                </div>
                <div className="content-section">
                  <h2 className="font-medium pb-5">{test.title}</h2>
                  <p className="font-light text-sm">{test.description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MaterialTest;
