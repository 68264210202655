import "../../style/capability.css";
import DCapabilities from "./DCapabilities";
import MCapabilities from "./MCapabilities";

const Capabilities = () => {
  return (
    <>
      <div className=" desktop-size">
        <DCapabilities />
      </div>
      <div className="mobile-size md:invisible">
        <MCapabilities/>
      </div>

    </>
  );
};

export default Capabilities;
