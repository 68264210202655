import { v4 as uuid } from "uuid";
import recruitmentPic1 from '../../assets/Images/services/recrPic1.jpg'
import recruitmentPic2 from '../../assets/Images/services/recPic2.jpg'
import recruitmentPic3 from '../../assets/Images/services/recrPic2.JPG'
const recruitmentPics = [
  {
    id: uuid(),
    image: recruitmentPic1
  },
  {
    id: uuid(),
    image: recruitmentPic2
  },
  {
    id: uuid(),
    image: recruitmentPic3
  },
]

export default recruitmentPics