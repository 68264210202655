import React from 'react'
import CeoMessage from '../../components/about/CeoMessage'
import Hero from '../../components/about/Hero'
import Leadership from '../../components/about/Leadership'
import OurMission from '../../components/about/OurMission'
import OurPartners from '../../components/about/OurPartners'
// import OurTeam from '../../components/about/OurTeam'
import WorkedWith from '../../components/about/WorkedWith'

const DAbout = () => {
  return (
    <div>
      <Hero/>
      <div className="section-break" id='our-leadership'></div>
      <CeoMessage/>
      <div className="section-break" id='meet-team'></div>
      <Leadership/>
      <div className="section-break" id='about-us'></div>
      {/* <OurTeam/> */}
      <OurMission/>
      <div className="section-break"></div>
      <OurPartners/>
      <div className="section-break"></div>
      <WorkedWith/>
      <div className="section-break"></div>
    </div>
  )
}

export default DAbout