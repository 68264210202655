import ourPartnersData from "../../data/about/ourPartners";

const OurPartners = () => {
  return (
    <div className="text-center  mt-40" id="hash-partner">
      <h2 className="title mb-24">Our <span className="title-red">Partners</span></h2>
      <ul className="flex items-center justify-center space-x-52">
        {ourPartnersData.map((partner) => {
          return (
            <li key={partner.id} className='partner-link flex items-center justify-center'>
              <a href={partner.link} target='_blank' rel="noreferrer">
                <img src={partner.img} alt="" />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OurPartners;
