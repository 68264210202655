import { v4 as uuid } from "uuid";
// aman Pics
import amanPic1 from "../../assets/Images/projects/amanAutoGas/pic1.jpg";
import amanPic2 from "../../assets/Images/projects/amanAutoGas/pic-2.jpg";
import amanPic3 from "../../assets/Images/projects/amanAutoGas/pic3.jpg";
import amanPic4 from "../../assets/Images/projects/amanAutoGas/pic4.jpg";
import amanPic5 from "../../assets/Images/projects/amanAutoGas/pic5.jpg";
import amanPic6 from "../../assets/Images/projects/amanAutoGas/pic6.jpg";
// NIU Pics
import NIUpic1 from "../../assets/Images/projects/NIU and SIU/pic1.jpg";
import NIUpic2 from "../../assets/Images/projects/NIU and SIU/pic2.jpg";
import NIUpic3 from "../../assets/Images/projects/NIU and SIU/pic3.jpg";

// ANA Pics
import ANApic1 from "../../assets/Images/projects/ANA/1.jpg";
import ANApic4 from "../../assets/Images/projects/ANA/4.jpg";
import ANApic5 from "../../assets/Images/projects/ANA/5.jpg";
import ANApic6 from "../../assets/Images/projects/ANA/6.jpg";
import ANApic7 from "../../assets/Images/projects/ANA/7.jpg";
import ANApic8 from "../../assets/Images/projects/ANA/8.jpg";
import ANApic9 from "../../assets/Images/projects/ANA/10.jpg";
import ANApic10 from "../../assets/Images/projects/ANA/11.jpg";
import ANApic11 from "../../assets/Images/projects/ANA/12.jpg";
import ANApic12 from "../../assets/Images/projects/ANA/13.jpg";
import ANApic13 from "../../assets/Images/projects/ANA/14.jpg";
import ANApic14 from "../../assets/Images/projects/ANA/15.jpg";
import ANApic15 from "../../assets/Images/projects/ANA/16.jpg";
import ANApic16 from "../../assets/Images/projects/ANA/17.jpg";
import ANApic17 from "../../assets/Images/projects/ANA/19.jpg";
import ANApic18 from "../../assets/Images/projects/ANA/20.jpg";
import ANApic19 from "../../assets/Images/projects/ANA/21.jpg";
import ANApic20 from "../../assets/Images/projects/ANA/20.jpg";
import ANApic21 from "../../assets/Images/projects/ANA/21.jpg";
// HQ yawez
import yawezPic1 from "../../assets/Images/projects/companyHqYawaz/1.JPG";
import yawezPic2 from "../../assets/Images/projects/companyHqYawaz/2.JPG";
import yawezPic3 from "../../assets/Images/projects/companyHqYawaz/3.JPG";
import yawezPic4 from "../../assets/Images/projects/companyHqYawaz/4.JPG";
import yawezPic5 from "../../assets/Images/projects/companyHqYawaz/5.JPG";
import yawezPic6 from "../../assets/Images/projects/companyHqYawaz/6.JPG";
import yawezPic7 from "../../assets/Images/projects/companyHqYawaz/7.JPG";
import yawezPic8 from "../../assets/Images/projects/companyHqYawaz/8.JPG";
import yawezPic9 from "../../assets/Images/projects/companyHqYawaz/9.JPG";
import yawezPic10 from "../../assets/Images/projects/companyHqYawaz/10.JPG";
// Jarf Pics
import JarfPic1 from "../../assets/Images/projects/HQjarf/1.jpg";
import JarfPic2 from "../../assets/Images/projects/HQjarf/2.jpg";
import JarfPic3 from "../../assets/Images/projects/HQjarf/3.jpg";
import JarfPic4 from "../../assets/Images/projects/HQjarf/4.jpg";
import JarfPic5 from "../../assets/Images/projects/HQjarf/5.jpg";
import JarfPic6 from "../../assets/Images/projects/HQjarf/6.jpg";

// Fire project - Jalalabad
import fireJBL1 from '../../assets/Images/projects/Fire Departments/fireJBL/1.JPG'
import fireJBL2 from '../../assets/Images/projects/Fire Departments/fireJBL/2.JPG'
import fireJBL3 from '../../assets/Images/projects/Fire Departments/fireJBL/3.JPG'
import fireJBL4 from '../../assets/Images/projects/Fire Departments/fireJBL/4.JPG'
import fireJBL5 from '../../assets/Images/projects/Fire Departments/fireJBL/5.JPG'
import fireJBL6 from '../../assets/Images/projects/Fire Departments/fireJBL/6.JPG'
import fireJBL7 from '../../assets/Images/projects/Fire Departments/fireJBL/7.JPG'
import fireJBL8 from '../../assets/Images/projects/Fire Departments/fireJBL/8.JPG'
import fireJBL9 from '../../assets/Images/projects/Fire Departments/fireJBL/9.JPG'
import fireJBL14 from '../../assets/Images/projects/Fire Departments/fireJBL/14.JPG'
import fireJBL15 from '../../assets/Images/projects/Fire Departments/fireJBL/15.JPG'
import fireJBL16 from '../../assets/Images/projects/Fire Departments/fireJBL/16.JPG'
// Fire Project - Kunar
import fireKNR1 from '../../assets/Images/projects/Fire Departments/fireKNR/1.JPG'
import fireKNR2 from '../../assets/Images/projects/Fire Departments/fireKNR/2.JPG'
import fireKNR3 from '../../assets/Images/projects/Fire Departments/fireKNR/3.JPG'
import fireKNR4 from '../../assets/Images/projects/Fire Departments/fireKNR/4.JPG'
import fireKNR5 from '../../assets/Images/projects/Fire Departments/fireKNR/5.JPG'
import fireKNR6 from '../../assets/Images/projects/Fire Departments/fireKNR/6.JPG'
import fireKNR7 from '../../assets/Images/projects/Fire Departments/fireKNR/7.JPG'
import fireKNR8 from '../../assets/Images/projects/Fire Departments/fireKNR/8.JPG'
import fireKNR9 from '../../assets/Images/projects/Fire Departments/fireKNR/9.JPG'
import fireKNR10 from '../../assets/Images/projects/Fire Departments/fireKNR/10.JPG'
import fireKNR11 from '../../assets/Images/projects/Fire Departments/fireKNR/11.JPG'
// Fire Project - Panjsher
import firePJR1 from '../../assets/Images/projects/Fire Departments/firePJR/1.jpg'
import firePJR2 from '../../assets/Images/projects/Fire Departments/firePJR/2.jpg'
import firePJR3 from '../../assets/Images/projects/Fire Departments/firePJR/3.jpg'
import firePJR4 from '../../assets/Images/projects/Fire Departments/firePJR/4.jpg'
import firePJR5 from '../../assets/Images/projects/Fire Departments/firePJR/5.jpg'
import firePJR6 from '../../assets/Images/projects/Fire Departments/firePJR/6.jpg'
import firePJR7 from '../../assets/Images/projects/Fire Departments/firePJR/7.jpg'
import firePJR8 from '../../assets/Images/projects/Fire Departments/firePJR/8.jpg'
import firePJR9 from '../../assets/Images/projects/Fire Departments/firePJR/9.jpg'
import firePJR10 from '../../assets/Images/projects/Fire Departments/firePJR/10.jpg'
import firePJR11 from '../../assets/Images/projects/Fire Departments/firePJR/11.jpg'
// Fire Project - Badakhshan
import fireBDS1 from '../../assets/Images/projects/Fire Departments/fireBDS/1.JPG'
import fireBDS2 from '../../assets/Images/projects/Fire Departments/fireBDS/2.JPG'
import fireBDS3 from '../../assets/Images/projects/Fire Departments/fireBDS/3.JPG'
import fireBDS4 from '../../assets/Images/projects/Fire Departments/fireBDS/4.JPG'
import fireBDS5 from '../../assets/Images/projects/Fire Departments/fireBDS/5.JPG'
import fireBDS6 from '../../assets/Images/projects/Fire Departments/fireBDS/6.JPG'
import fireBDS7 from '../../assets/Images/projects/Fire Departments/fireBDS/7.JPG'
// KDR project 
import KDRpic1 from '../../assets/Images/projects/KDR/1.jpg'
import KDRpic2 from '../../assets/Images/projects/KDR/2.jpg'
import KDRpic3 from '../../assets/Images/projects/KDR/3.jpg'
import KDRpic4 from '../../assets/Images/projects/KDR/4.jpg'
import KDRpic5 from '../../assets/Images/projects/KDR/5.jpg'
import KDRpic6 from '../../assets/Images/projects/KDR/6.jpg'
import KDRpic7 from '../../assets/Images/projects/KDR/7.jpg'
// BAMYAN
import BMY1 from '../../assets/Images/projects/BMY/1.JPG'
import BMY2 from '../../assets/Images/projects/BMY/2.JPG'
import BMY3 from '../../assets/Images/projects/BMY/3.JPG'
import BMY4 from '../../assets/Images/projects/BMY/4.JPG'
import BMY5 from '../../assets/Images/projects/BMY/5.JPG'
import BMY6 from '../../assets/Images/projects/BMY/6.JPG'
import BMY7 from '../../assets/Images/projects/BMY/7.JPG'
import BMY8 from '../../assets/Images/projects/BMY/8.JPG'
import BMY9 from '../../assets/Images/projects/BMY/9.JPG'
import BMY10 from '../../assets/Images/projects/BMY/10.JPG'
import BMY11 from '../../assets/Images/projects/BMY/11.JPG'
const allProjectsData = [
  // project 1
  {
    id: uuid(),
    image: [
      { pic1: amanPic1 },
      { pic1: amanPic2 },
      { pic1: amanPic3 },
      { pic1: amanPic4 },
      { pic1: amanPic5 },
      { pic1: amanPic6 },
    ],
    coverProject: [{ projectImage: amanPic3 }],
    title:
      "Construction of Petrol Pump and LPG Stations several locations in Afghanistan.",
    decription:
      "This project consisted of the design and construction of petrol reservoir, LPG storage facility, dispensing units for both gas and oil, office building, storage facility, concrete parking area, steel structure canopy for dispensing units, LPG conversion workshop and all utilities.",
    location: "Afg",
  },
  // project 2 NIU
  {
    id: uuid(),
    image: [{ pic1: NIUpic1 }, { pic1: NIUpic2 }, { pic1: NIUpic3 }],
    coverProject: [{ projectImage: NIUpic2 }],
    title:
      "Construction and repair of NIU SIU Buildings Behind Kabul airport Qasaba, Kabul, Afghanistan",
    decription:
      "This project was one of the latest completed projects from SBGroup. this project was the most rapidly implemented project. This project contained of 5 separate buildings renovations. The project consisted of the adapt design, repair of the buildings with complete aspects, construction of the drainage ditches, and connection of water, sewer and electric with main supply and city connections. ",
    location: "Kabul",
  },
  // KDR project
  {
    id: uuid(),
    image: [
      { pic1: KDRpic1 },
      { pic1: KDRpic2 },
      { pic1: KDRpic3 },
      { pic1: KDRpic4 },
      { pic1: KDRpic5 },
      { pic1: KDRpic6 },
      { pic1: KDRpic7 },
    ],
    coverProject: [{ projectImage: KDRpic2 }],
    title: "ANA Rpr Hangar 633 & 3rd SMW Kandahar Air Base,",
    decription:
      "The project consisted of two hangars repair work (3rd Special Mission Wings and 633 Hangar) at Kandahar Air base. The hangars were not at the fully functioning level. SBGroup got this opportunity to repair and bring the Hangars to fully functioning phases at a very finest quality.",
    location: "Badakhshan",
  },
  // project 4 ANA
  {
    id: uuid(),
    image: [
      { pic1: ANApic1 },
      { pic1: ANApic4 },
      { pic1: ANApic5 },
      { pic1: ANApic6 },
      { pic1: ANApic7 },
      { pic1: ANApic8 },
      { pic1: ANApic9 },
      { pic1: ANApic10 },
      { pic1: ANApic11 },
      { pic1: ANApic12 },
      { pic1: ANApic13 },
      { pic1: ANApic14 },
      { pic1: ANApic15 },
      { pic1: ANApic16 },
      { pic1: ANApic17 },
      { pic1: ANApic18 },
      { pic1: ANApic19 },
      { pic1: ANApic20 },
      { pic1: ANApic21 },
    ],
    coverProject: [{ projectImage: ANApic21 }],
    title: "ANA Presidential Air Wing Relocation SHKIA.",
    decription:
      "The project included the renovation and commissioning of the existing VIP Terminal and the design & construction of a new 2 bays Hangar & Back-shops Facility and a new SOF and all utility connections. It was a tough project for its enormous structure form and security of the stakeholders. This was the first Hangar project designed by SBGroup in Afghanistan and erected successfully without any single.",
    location: "Kabul",
  },
  // project 5 Yawez
  {
    id: uuid(),
    image: [
      { pic1: yawezPic1 },
      { pic1: yawezPic2 },
      { pic1: yawezPic3 },
      { pic1: yawezPic4 },
      { pic1: yawezPic5 },
      { pic1: yawezPic6 },
      { pic1: yawezPic7 },
      { pic1: yawezPic8 },
      { pic1: yawezPic9 },
      { pic1: yawezPic10 },
    ],
    coverProject: [{ projectImage: yawezPic7 }],
    title: "Company HQ (Austere), Yawez, Shar-e-Bozorg, Badakhshan,",
    decription:
      "This project was explained as the design and construction of all facilities including utilities. The site was extremely remote, procurement and supply for this construction was a big challenge. The project was located 140 km from Fayezabad. The project was nominated for ANBP units. SBGroup proudly completed the project and delivered the promise to the client.",
    location: "Badakhshan",
  },
  // project 6 JARF
  {
    id: uuid(),
    image: [
      { pic1: JarfPic1 },
      { pic1: JarfPic2 },
      { pic1: JarfPic3 },
      { pic1: JarfPic4 },
      { pic1: JarfPic5 },
      { pic1: JarfPic6 },
    ],
    coverProject: [{ projectImage: JarfPic6 }],
    title: "BP Company HQ (Austere), Jarf, Badakhshan ",
    decription:
      "designed and constructed facilities to support the Afghanistan National Border Police in Badakhshan Province, Afghanistan. The site was extremely remote. SBGroup accepted the challenge and established a supply chain to the project site. There was no access road or bridge to deliver our material and equipment. SBGroup hired boats and barges and accessed the site via Tajikistan, including arranging all diplomatic permissions. We could only supply while the Amu river’s flow was low",
    location: "Badakhshan",
  },
  // Fire Project JLB
  {
    id: uuid(),
    image: [
      { pic1: fireJBL1 },
      { pic1: fireJBL2 },
      { pic1: fireJBL3 },
      { pic1: fireJBL4 },
      { pic1: fireJBL5 },
      { pic1: fireJBL6 },
      { pic1: fireJBL7 },
      { pic1: fireJBL8 },
      { pic1: fireJBL9 },
      { pic1: fireJBL14 },
      { pic1: fireJBL15 },
      { pic1: fireJBL16 },
    ],
    coverProject: [{ projectImage: fireJBL9 }],
    title: "ANP Fire Department Class B, Jalalabad ",
    decription:
      "",
    location: "",
  },
  // Fire project KNR
  {
    id: uuid(),
    image: [
      { pic1: fireKNR1 },
      { pic1: fireKNR2 },
      { pic1: fireKNR3 },
      { pic1: fireKNR4 },
      { pic1: fireKNR5 },
      { pic1: fireKNR6 },
      { pic1: fireKNR7 },
      { pic1: fireKNR8 },
      { pic1: fireKNR9 },
      { pic1: fireKNR10 },
      { pic1: fireKNR11 },
    ],
    coverProject: [{ projectImage: fireKNR4 }],
    title: "ANP Fire Department Class B, Kunar",
    decription:
      "",
    location: "",
  },
  // Fire project PJR
  {
    id: uuid(),
    image: [
      { pic1: firePJR1 },
      { pic1: firePJR2 },
      { pic1: firePJR3 },
      { pic1: firePJR4 },
      { pic1: firePJR5 },
      { pic1: firePJR6 },
      { pic1: firePJR7 },
      { pic1: firePJR8 },
      { pic1: firePJR9 },
      { pic1: firePJR10 },
      { pic1: firePJR11 },
    ],
    coverProject: [{ projectImage: firePJR11 }],
    title: "ANP Fire Department (Class B) Panjshir",
    decription:
      "",
    location: "",
  },
  // Fire project 4
  {
    id: uuid(),
    image: [
      { pic1: fireBDS1 },
      { pic1: fireBDS2 },
      { pic1: fireBDS3 },
      { pic1: fireBDS4 },
      { pic1: fireBDS5 },
      { pic1: fireBDS6 },
      { pic1: fireBDS7 },
    ],
    coverProject: [{ projectImage: fireBDS4 }],
    title: "ANP Fire Department Class B, Feyzabad, Badakshan",
    decription:
      "",
    location: "",
  },
  // Bamyan
  {
    id: uuid(),
    image: [
      { pic1: BMY1 },
      { pic1: BMY2 },
      { pic1: BMY3 },
      { pic1: BMY4 },
      { pic1: BMY5 },
      { pic1: BMY6 },
      { pic1: BMY7 },
      { pic1: BMY8 },
      { pic1: BMY9 },
      { pic1: BMY10 },
      { pic1: BMY11},
    ],
    coverProject: [{ projectImage: BMY4 }],
    title: "Uniform Police District HQ Bamyan Afghanistan.",
    decription:
      "The project consisted of the design and construction of a new Uniform Police UP District Headquarters for the Afghan National Police (ANP) in Sayghan District, Bamyan Province, Afghanistan. The project was defined as the design, material, labor, and equipment to construct buildings, parking area, utilities, and other infrastructure for a design population of 20 personnel of Afghan National Police (ANP).",
    location: "",
  },
];

export default allProjectsData;
