import heroPic from "../../assets/Images/services/services_Hero.jpg";
import logisticData from "../../data/servicesData/logistics";
// import infrastructureData from "../../data/servicesData/infrastructureImg";
import constructionImg from "../../assets/Images/services/constructionImg.jpg";
import waterSectorImg from "../../assets/Images/services/waterSector.jpg";
import DrTimPic from "../../assets/Images/services/DrTim.jpg";
import waterPicture from "../../assets/Images/services/WaterCrisis.jpg";
import renewableImg from "../../assets/Images/services/renewableMain.jpg";
import solarHeroPic from "../../assets/Images/services/solarHeroPic.jpg";
import recruitmentPics from "../../data/servicesData/recruitmentPics";
import recruitmentPic from "../../assets/Images/services/recruitmentPic.png";
import materialTestData from "../../data/servicesData/materialTestData";
import k9Pic from "../../assets/Images/services/k9.jpg";
import PEBpic from "../../assets/Images/services/PEB.jpg";
const MServices = () => {
  return (
    <div className="overflow-x-hidden">
      {/* Hero services */}
      <div className="overflow-hidden" id="">
        <img src={heroPic} alt="" className="min-h-[50vh] object-cover" />
        <h2 className="text-3xl font-medium text-white text-center object-right -mt-20">
          OUR SERVICES
        </h2>
        <p className="font-light leading-7 hero-service-paragraph text-justify mt-16 p-2">
          SBGroup offers a full range of professional engineering services
          tailored to the needs of developers. Our project teams are skilled in
          disciplines ranging from building, highways, bridge & culvert, water
          supply structures, drainage, water resources and infrastructure. Our
          services include planning, environmental impact assessment, site
          appraisals, feasibility studies, concept layout, survey, architectural
          services, construction, refurbishment, structural integrity checking,
          and failure investigation. We can accomplish any kind of vertical
          structural such as Housing, Hospitals, Offices, Factories and
          warehousing, Car parks, Schools, Military facilities, Masts and
          towers, Leisure facilities, tunnels, culverts and subways, roads,
          drainage and flood control structures, bridges, earth retaining
          structures, and water retaining structures. Our Design-Build approach
          provides a unique value proposition to our clients, ensuring the
          seamless integration of design and construction activities. We bring
          top value to our clients through keeping the focus on the project's
          goals. <br /> <br /> Our proven 100+ million track record and our
          repeat clients are a testament to our expertise and high quality of
          work.
        </p>
      </div>
      {/* Engineering */}
      {/* <div className="title-box p-2 mt-16 mb-10">
        <h2 className="title mb-5 text-center">
          The Pre-Engineering
          <span className="title-red"> Buildings</span>
        </h2>
        <p className="font-light">
          A pre-engineered steel building is a modern technology where the
          complete designing is done at the factory and the building components
          are fetched to the site for erection and they are erected at site with
          the help of cranes. SBGroup facilitate your all type of design
          manufacturing and erection of any heavy metal industries (K-SPAN and
          PEB) Air force workshops & Hangars. SBGroup also offer a verity of
          heavy steel like High-capacity fuel tanks and fuel storages, metal
          doors and windows and roll up doors. SBGroup recently completed a
          project W5J9JE-18-C-0006 ANA Presidential Air Wing Relocation SHKIA in
          Kabul. The project contained other construction buildings with a two
          bay PEB hangar for MI-17 Aircrafts, which is the biggest PEB building
          in Afghanistan. The project included design, manufacturing, and
          erection and installation of heavy duty 10Ton bridge cranes, all
          utilities and a workshop of air crafts was the part of the project.
        </p>
      </div> */}
      {/* infrastructure */}
      {/* <div className="text-center flex flex-col justify-center items-center">
        <h2 className="font-medium mb-10">Infrastructure</h2>
        <ul className="grid grid-cols-2 gap-5">
          {infrastructureData.map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col justify-center items-center"
              >
                <img src={data.icon} alt="" className="h-[3em] " />
                <h2>{data.title}</h2>
              </li>
            );
          })}
        </ul>
      </div> */}
      {/* Construction */}
      <div className="construction-container flex items-center mt-10">
        <div className="construction-content">
          <h2 className="title mb-5 text-center">
            Our Work <span className="title-red">Capability</span>
          </h2>
          <img src={constructionImg} alt="" className="construction-img" />
          <p className="font-light p-2">
            Our capability is to provide a `cradle to grave' construction
            service advising on all aspects of the process, including
            procurement, risk assessment and management during the project. We
            also help resolve disputes if they arise. We act for a wide range of
            clients, including contractors, owners, operators, and consultants.
            We have worked on some of the largest and most technically complex
            projects in Afghanistan.
          </p>
        </div>
      </div>
      {/* Water sector */}
      <div className="water-sector-container text-center mt-8" id="">
        <h2 className="title">
          Water <span className="title-red">Sector</span>
        </h2>
        <div className="water-sector-img">
          <img src={waterSectorImg} alt="" className="object-cover" />
          <div className="-mt-16">
            <h2 className="text-white text-2xl mb-8">
              Solving the Water Crisis
            </h2>
            <p className=" font-light text-xl">
              Accelerating Water Sector Transformation
            </p>
          </div>
        </div>
      </div>
      {/* Dr Tim Bio */}
      <div className="flex flex-col items-center">
        <div className="tim-image">
          <img src={DrTimPic} alt="Dr Tim" />
        </div>
        <div className="">
          <h2 className="text-lg text-center ">Dr. Tim Harrold</h2>
          <p className="text-green mb-5 font-light text-center">
            Water Sector Advisor - <br /> Sadath Business Group
          </p>
          <p className="font-light p-2">
            I am an Australian water engineer. For 14 years up to November 2019
            I was planning engineer at Wannon Water in southwest Victoria
            Australia. I was lead author of Wannon Water’s Urban Water Strategy,
            a 50 year plan for water supply security for towns in southwest
            Victoria. Now I want to help provide drinking water for the people
            of Kabul. This is integrated with wastewater solutions in the
            context of the Kabul groundwater basin. <br /> <br /> The mission of
            SBGroup’s water sector is to help the development of Afghanistan’s
            water sector. Indirectly this will save lives as people will die if
            they do not have enough clean water. As sector director, my
            priorities are: Educational development of Afghan water managers;
            Positive personal relationships with funding agencies, government
            and government agencies, ngos and private industry; <br /> To
            develop new water projects in cooperation with other stakeholders.
          </p>
        </div>
      </div>
      {/* Water projects */}
      <div className="p-2">
        <h2 className="text-lg font-medium">Our Projects</h2>

        <div className="pt-7 flex flex-col">
          <div className="project-description">
            <h2 className="font-medium mb-5">
              Kabul <span className="text-red">Water Crisis</span> - A National
              Priority
            </h2>
            <p className="leading-7 font-light">
              Kabul has a water crisis. Groundwater is the only current source
              of drinking water for 11 million people living in the Kabul
              groundwater basin. Rapidly falling groundwater levels demonstrate
              that new sources of water are needed. It has been estimated that
              US$2 billion of investment is required over the next 15 years to
              meet these needs. My own experience over 2006-2019 has been to
              help plan for and implement a similar investment of US$200 million
              for the people of southwest Victoria Australia and now I have a
              long term career goal to see Kabul’s drinking water needs met.
            </p>
          </div>
          <div className="pt-10">
            <h2 className="text-lg mb-5 font-medium">Our solutions</h2>
            <ul className="pl-4 leading-8 font-light">
              <li>• Increase infiltration in the city</li>
              <li>• Restore existing wastewater treatment plants</li>
              <li>• Regulate Industry</li>
              <li>• Irrigated agriculture: improve water efficiency</li>
              <li>• Do not waste water</li>
              <li>• Public Awareness</li>
            </ul>
          </div>
          <div className="pt-10">
            <img src={waterPicture} alt="" className="" />
          </div>
        </div>
      </div>
      {/* Renewable Energy */}
      <div className="mt-10">
        <h2 className="title text-center mb-10 ">
          Renewable <span className="title-red">Energy</span>
        </h2>
        <div className="flex flex-col">
          <div className="renewable-img-box">
            <img src={renewableImg} alt="" />
          </div>
          <div className="p-2">
            <h2 className="text-2xl mb-5 font-light leading-8">
              <span className="text-green">Green</span> Energy For Everyone
            </h2>
            <p className="font-light">
              From planning to fulfilment of solar systems, SBGroup offer
              competent customer service. The highest quality of individual
              components, especially solar modules, is at the forefront of our
              business. The type of solar module must be chosen to suit specific
              circumstances - decisive factors include the load capacity of the
              roof, wind load, snow load regions, the size of the roof surface,
              the roof pitch and the desired efficiency. In addition, SBGroup
              places great importance to ensure that every solar module delivers
              with its efficiency and longevity.
            </p>
          </div>
        </div>
      </div>
      {/* Renewable Projects */}
      <div>
        <h2 className="text-xl font-medium text-center mt-6">
          Our Renewable Projects
        </h2>
        <div className="renewable-projects-container grid grid-cols-1 mt-5 items-center ">
          <img src={solarHeroPic} alt="solar hero" />
          <div className="renewable-projects-content p-2 mt-4">
            <h2 className="text-xl mb-2">
              Solar Hero <span className="font-medium">Afghanistan</span>
            </h2>
            <p className="font-light">
              Solar Hero Afghanistan was formed in 2020 with SBGroup being a
              major stakeholder. It was formed as a partnership with AE Solar
              Germany.
            </p>
          </div>
        </div>
      </div>
      {/* Recruitment */}
      <div className="flex-col mt-7">
        <div className="title-section mb-24">
          <h2 className="title text-center">
            Recruitment & <span className="text-green">Training</span>
          </h2>
          <p className="font-light p-2 pt-5">
            Irrespective of where you are in your career, Irrespective of where
            you are in your career whether you are a contractor, or work at an
            organization, there is always room to grow in your career. SBGroup
            will assist you to determine which training will benefit your unique
            needs and situation.
          </p>
        </div>

        <div className="flex flex-col items-center justify-center">
          <img src={recruitmentPic} alt="" />
          <p className="font-light p-2">
            Functioning recruitment and employee selection ensures that the
            right candidates are placed in the right job, but it is not always
            an easy process. Having a well-designed recruitment process helps
            you increase job satisfaction, reduce wastage of resources, achieve
            better preservation of employees and advances output.
          </p>
        </div>

        <div className="team-example">
          <p className="font-light text-justify p-2">
            SBGroup will provide you all the critical recruiting skills like how
            to design a best-practice selection process, touchable goals,
            implementation of new staff, interviewing practices and monitoring
            the results of your recruitment efforts.
          </p>
          <ul className="grid grid-cols-3 gap-2 mt-4">
            {recruitmentPics.map((pic) => {
              return (
                <li className="recruitment-pic-list" key={pic.id}>
                  <img src={pic.image} alt="" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* Procurment */}
      <div>
        <div className="procurment-main-section text-center mb-10">
          <h2 className="title mt-12">
            Procurement & <span className="text-green">Supply</span>
          </h2>
        </div>
        <div className="logistic-solution p-2">
          <h2 className="font-medium text-lg mb-4">Logistic solutions</h2>
          <p className="font-light">
            Over the progression of decades, SBGroup has earned a reputation for
            delivering an exceptional customer experience and complete customer
            satisfaction on every task order. We offer a full range of
            international and domestic transportation services via air, ocean,
            and ground around the globe, No matter the size of the task nor the
            target, you can be confident we'll deliver.
          </p>
        </div>

        <ul className="grid grid-cols-1 gap-16 mt-10 p-3" id="">
          {logisticData.map((data) => {
            return (
              <li key={data.id} className="shadow-md pb-6">
                <img src={data.image} alt="logistic data" className="mb-5" />
                <h2 className="font-medium p-2 pb-4">{data.title}</h2>
                <p className="font-light text-xs p-2">{data.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
      {/* Material Test */}
      <div className="mt-10">
        <div className="material-test-main-section mb-5">
          <h2 className="title pb-2 text-center">
            Material Test <span className="text-green">Laboratory</span>
          </h2>
          <p className="font-light p-2">
            For the sake of better project implementation and quality work,
            SBGroup have established equipped laboratory. We proudly test your
            A2Z Concrete, Asphalt, Soil etc for your construction projects. Our
            Lab is USACE certified.
          </p>
        </div>
        <div className="material-test-lists">
          <ul className="p-2">
            {materialTestData.map((test) => {
              return (
                <li
                  key={test.id}
                  className="test-material-list flex mt-7 items-center"
                >
                  <div className="image-section pr-2 pl-2">
                    <img src={test.image} alt="" />
                  </div>
                  <div className="content-section">
                    <h2 className="font-medium pb-2">{test.title}</h2>
                    <p className="font-light text-sm">{test.description}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* K9 */}
      <div className="flex flex-col items-center mt-12">
        <h2 className="title text-center mb-5">
          LucasK9 Working dogs for <span className="title-red">K9 units</span>
        </h2>
        <p className="font-light p-2">
          Since 2004 our partner LucasK9 have been professionally dealing with
          police dogs performance. who spent most of 2005 observing different
          systems of training dogs searching for explosives and drugs in
          American seminars. Training dogs let us better understand the law
          enforcement canine need. They had the opportunity and pleasure to work
          and train dogs for American police units.
        </p>
        <div className="flex flex-col text-left mt-12 items-center">
          <img src={k9Pic} alt="k9 dog pic" />
          <div className="k9-content p-2">
            <h2 className="text-medium text-2xl">Our services</h2>
            <p className="mt-4 leading-6">
              These beautiful, great dogs are carefully selected and pre-trained
              by Lucas K9, whereas the full training of majority of them is
              completed by local K9 centers and their trainers
              <br />
              <br />
              We would like to thank all the trainers of our dogs. We do
              appreciate great effort and passion they put into preparing our
              dogs to become valuable assets to K9 police units.
            </p>
            <div className="pt-2">
              <a
                href="https://lucask9.com/home"
                target={"_blank"}
                rel="noreferrer"
                className="text-green"
              >
                Link to visit LucasK9 website
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* PEB */}
      <div className="flex-col mt-10">
        <h2 className="title mb-3 text-center">
          Pre Engineered Steel Buildings{" "}
          <span className="title-red">(PEB)</span>
        </h2>
        <img src={PEBpic} alt="" />
        <p className="p-2">
          Design, manufacture and installation of all kinds of heavy metal
          industries (KSPAN and PEMB) air force work shops, Hangar and the
          following works:
        </p>
        <br />
        <p className="p-2 leading-7">
          1: Construction and installation of all types of industrial hangrs,
          warehouses, sports gymnasiums, metal skeletons for all types of tall
          houses. <br />
          PEB Residential or office connections including insulation, air
          conditioning, electrical, and plumbing upon request. <br />
          2: Roof top cranes. <br />
          3: Metal bridges, towers for electricity and telecommunications
          systems.
          <br />
          4: High capacity fuel tanks and storage. <br />
          5: Metal doors and windows and Roll up Doors
        </p>
      </div>
    </div>
  );
};

export default MServices;
