import { v4 as uuid } from "uuid";
import UNlogo from '../../assets/logos/UN.png'
import AUGlogo from '../../assets/logos/australiaGov.png'
import ISAFlogo from '../../assets/logos/ISAF.png'
import NATOlogo from '../../assets/logos/NATO.png'
import SMEClogo from '../../assets/logos/SMEC.png'
import USAClogo from '../../assets/logos/USAcorps.png'
import UNICEFlogo from '../../assets/logos/UNICEF.png'
import otherlogo from '../../assets/logos/USACE.png'
import TMClogo from '../../assets/logos/TMC.JPG'

const workedWithData = [
  {
    id: uuid(),
    logo: UNlogo,
    link: 'https://un.org',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: UNICEFlogo,
    link: 'https://www.unicef.org/',
    description: 'We worked with UNICEF on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: AUGlogo,
    link: 'https://www.australia.gov.au',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: USAClogo,
    link: 'https://www.af.mil',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: SMEClogo,
    link: 'https://www.smec.com/au',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: ISAFlogo,
    link: 'https://www.nato.int/cps/en/natohq/topics_69366.htm',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: NATOlogo,
    link: 'https://nato.int',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: otherlogo,
    link: 'https://www.usace.army.mil',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },
  {
    id: uuid(),
    logo: TMClogo,
    link: 'http://tmcgps.com/',
    description: 'We worked with UN on 4 projects that were all about contruction'
  },

]

export default workedWithData