import '../../style/contact.css'
import DContact from './DContact'
import MContact from './MContact'

const Contact = () => {
  return (
    <div>
      <div className="desktop-size">
      <DContact/>
      </div>
      <div className="mobile-size md:invisible">
        <MContact/>
      </div>
    </div>
  )
}

export default Contact