import appriciateVideo from "../../assets/videos/appreciateVideo.mp4";

const AppreciateUS = () => {
  return (
    <div className="flex flex-col text-center justify-center items-center mt-36">
      <div className="appreciate-content pl-52 pr-52 mb-6">
        <h2 className="title mb-5">
          What does the <span className="title-red">Highest Spending</span>{" "}
          Military Say About Us
        </h2>
        <p className="font-light">
          SBGroup
          completed a project of VIP Presidential Terminal repair, construction
          of a security operation building and a two bay PEB hanger for MI-17
          Aircraft, the biggest PEB building in Afghanistan. The US Air Force
          came for inspection, including Lt. Col Scott Yeatman, the commander of
          US Air Force of Afghanistan. Here are his remarks.
        </p>
      </div>
      <div className="appreciate-vide">
        <video src={appriciateVideo} controls></video>
      </div>
    </div>
  );
};

export default AppreciateUS;
