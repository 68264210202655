import waterPicture from '../../assets/Images/services/WaterCrisis.jpg'

const WaterProjects = () => {
  return (
    <div className="pl-32 pr-32">
      <h2 className="text-lg font-medium">Our Projects</h2>

      <div className="warter-project-info pt-7 flex space-x-24">
        <div className="project-description">
          <h2 className="font-medium mb-5">
            Kabul <span className="text-red">Water Crisis</span> - A National
            Priority
          </h2>
          <p className='w-96 leading-7 font-light'>
          Kabul has a water crisis. Groundwater is the only current source of drinking water for 11 million people living in the Kabul groundwater basin. Rapidly falling groundwater levels demonstrate that new sources of water are needed. It has been estimated that US$2 billion of investment is required over the next 15 years to meet these needs. My own experience over 2006-2019 has been to help plan for and implement a similar investment of US$200 million for the people of southwest Victoria Australia and now I have a long term career goal to see Kabul’s drinking water needs met.
          </p>
        </div>
        <div className="solutions pt-10">
          <h2 className="text-lg mb-5 font-medium">Our solutions</h2>
          <ul className='pl-4 leading-8 font-light'>
            <li>• Increase infiltration in the city</li>
            <li>• Restore existing wastewater treatment plants</li>
            <li>• Regulate Industry</li>
            <li>• Irrigated agriculture: improve water efficiency</li>
            <li>• Do not waste water</li>
            <li>• Public Awareness</li>
          </ul>
        </div>
        <div className="water-pic pt-10">
          <img src={waterPicture} alt="" className='h-56'/>
        </div>
      </div>
    </div>
  );
};

export default WaterProjects;
