import heroImages from "../../assets/Images/homePage/Hero-mb.jpg";
import offers from "../../data/homeData/Offer";
import figureImage from "../../assets/Images/homePage/chooseFigure.jpg";
import appriciateVideo from "../../assets/videos/appreciateVideo.mp4";
import motivatedPic from "../../assets/Images/homePage/motivatedPic.jpg";
import jarfPic1 from "../../assets/Images/homePage/jarfPic1.jpg";
import jarfPic2 from "../../assets/Images/homePage/jarfPic2.JPG";
import jarfPic3 from "../../assets/Images/homePage/jarfPic3.JPG";
const MHome = () => {
  return (
    <>
      <div className="overflow-y-hidden h-screen mb-7" >
        <img
          src={heroImages}
          alt="Hero-Pic"
          className="h-[50em] object-cover object-center w-full"
        />
        <div className=" pl-6 pr-6 text-center -mt-[12em]">
          <h2 className="text-white font-medium mb-4 text-xl">
            FROM CONCEPT TO CREATION
          </h2>
          <p className="text-justify text-white font-light">
            We have a wide range of diverse experience in the world's most
            remote areas, with capacity and capability to operate in Central
            Asia, Australasia, Eastern Europe and Africa.
          </p>
        </div>
      </div>
      {/* what we offer? */}
      <div className="home-offers" id="what-we-do">
        <h2 className="title mb-5">
          What <span className="title-red">We</span> Offer?
        </h2>
        <ul className="offer-lists grid grid-rows-1">
          {offers.map((offer) => {
            return (
              <li key={offer.id}>
                <img src={offer.image} alt="offer pic" />
                <h2 className="font-medium mb-4">{offer.title}</h2>
                <p className="font-light text-gray-dark">{offer.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
      {/* Why choose us */}
      <div
        className="choose-us flex flex-col justify-between space-x-5 pt-24"
        id="hash-experince-footer"
      >
        <div className="choose-figure">
          <h2 className="title mb-5 text-center">
            Why <span className="title-red">Choose </span>Us?
          </h2>
          <img
            src={figureImage}
            alt="choose figure"
            className="h-[18em] w-full"
          />
          <div className="choose-figure-data grid grid-cols-2 grid-rows-2 gap-16">
            <div className="figures-num">
              <h2>200+</h2>
              <p>Employees</p>
            </div>
            <div className="figures-num">
              <h2>20+</h2>
              <p>Projects</p>
            </div>
            <div className="figures-num">
              <h2>15+</h2>
              <p>Years</p>
            </div>
            <div className="figures-num">
              <h2>100+</h2>
              <p>Million Track Record</p>
            </div>
          </div>
        </div>
        <div className=" pt-5 pr-5">
          <p className="font-light  leading-6 mb-6">
            Here at SBGroup we pride ourselves in proactive customerrelations
            and attention to detail in theservices we provide. We work with you
            inevery aspect of your project from initialplanning to the finishing
            touches. We focus on results and added value.
            <br />
            <br /> So why choose us:
          </p>
          <div className="bulletpoints space-y-4">
            <div className="font-light">
              • Because we’ve been there and done it. When you choose us, you’ll
              feel the benefit of 15+ years’ experience of build &
              constructions.
            </div>
            <div className="font-light">
              • Because we’re easy to work with. We take the work seriously, but
              not ourselves. We’re not prickly, precious or pretentious.
            </div>
            <div className="font-light">
              • Because we focus on results. For us, it’s all about what adds
              value for you and your business. Above all, we want our words to
              work for you.
            </div>
          </div>
          {/* <button className="btn-green mt-7">Read More</button> */}
        </div>
      </div>
      {/* Video Appriciate */}
      <div className="flex flex-col text-center justify-center items-center mt-20 p-3">
        <div className="appreciate-content">
          <h2 className="title mb-5">
            What does the <span className="title-red">Highest Spending</span>{" "}
            Military Say About Us
          </h2>
          <p className="font-light text-justify">
            SBGroup completed a project of VIP Presidential Terminal repair,
            construction of a security operation building and a two bay PEB
            hanger for MI-17 Aircraft, the biggest PEB building in Afghanistan.
            The US Air Force came for inspection, including Lt. Col Scott
            Yeatman, the commander of US Air Force of Afghanistan. Here are his
            remarks.
          </p>
        </div>
        <div className="mt-5">
          <video src={appriciateVideo} controls></video>
        </div>
      </div>
      {/* Motivated */}
      <div className="flex flex-col justify-center items-center mt-32" id="hash-experince-footer">
        <div className="p-4">
          <h2 className="title mb-5">
            Motivated to Solve
            <span className="title-red">Complex Problems</span>
          </h2>
          <p className="font-light">
            SB Group designed and constructed facilities to support the
            Afghanistan Border Police in Badakhshan Province. The site was
            extremely remote. SBGroup accepted the challenge and established a
            supply chain to the project site. There was no access road or bridge
            to deliver our material and equipment. SBGroup hired boats and
            barges and accessed the site via Tajikistan, including arranging all
            diplomatic permissions. We could only supply while the Amu river’s
            flow was low.
          </p>
        </div>
        <div className="motivated-image grid grid-cols-2 gap-4 p-4">
          <div className="pic1">
            <img src={jarfPic2} alt="" />
          </div>
          <div className="pic1">
            <img src={jarfPic1} alt="" />
          </div>
          <div className="pic1">
            <img src={motivatedPic} alt="" />
          </div>
          <div className="pic1">
            <img src={jarfPic3} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MHome;
