import allProjectsData from "../../../data/projects/allProjectsData";
import { FaAngleLeft, FaAngleRight, FaRegTimesCircle } from "react-icons/fa";
const Project1 = () => {
  const slideLeft = () => {
    var slider = document.getElementById("slider-8");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById("slider-8");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  // popup image function
  document.querySelectorAll(".image-container img").forEach((image) => {
    image.onclick = () => {
      document.querySelector(".image-popup").style.display = "block";
      document.querySelector(".image-popup img").src =
        image.getAttribute("src");
    };
  });
  // close pop-up
  const closePopup = () => {
    document.querySelector(".image-popup").style.display = "none";
  };
  return (
    <div className="image-container">
      <ul className="pl-2 pr-2 ">
        {allProjectsData.slice(8, 9).map((project) => {
          return (
            <li className=" mb-24" key={project.id}>
              <h2 className="text-xl font-medium mb-5 text-center">
                {project.title}
              </h2>
              <div className="projects-images relative flex items-center">
                <FaAngleLeft size={40} onClick={slideLeft} />
                <div
                  id="slider-8"
                  className="shadow-md p-4 w-full h-full overflow-x-scroll overflow-y-hidden scroll whitespace-nowrap scroll-smooth "
                >
                  {project.image.map((image, index) => {
                    return (
                      <img
                        src={image.pic1}
                        alt="pic of the project"
                        key={index}
                        className="w-[350px] h-[220px] bg-cover inline-block p-3 cursor-pointer hover:scale-105 ease-in-out duration-300"
                      />
                    );
                  })}
                </div>
                <FaAngleRight size={40} onClick={slideRight} />
              </div>
            </li>
          );
        })}
      </ul>

      <div className="image-popup">
        <span onClick={closePopup}>
          <FaRegTimesCircle size={40} />
        </span>
        <img src={""} alt="" />
      </div>
    </div>
  );
};

export default Project1;
