import React from 'react'
import AppreciateUS from '../../components/home/AppreciateUS'
import ChooseUs from '../../components/home/ChooseUs'
import Hero from '../../components/home/Hero'
import Motivated from '../../components/home/Motivated'
import Offer from '../../components/home/Offer'
import OurStory from '../../components/home/OurStory'

const DHome = () => {
  return (
    <div>
      <Hero/>
      <div className="section-break"></div>
      <ChooseUs/>
      <div className="section-break" id='what-we-do'></div>
      <Offer/>
      <div className="section-break"></div>
      <OurStory/>
      <div className="section-break"></div>
      <AppreciateUS/>
      <div className="section-break"></div>
      <Motivated/>
      <div className="section-break"></div>
    </div>
  )
}

export default DHome