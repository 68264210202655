import React from "react";
// import allProjectsData from "../../data/capability/Projects";
import allProjectsData2 from "../../data/projects/allProjectsData";

const Projects = () => {
  return (
    <div className="pl-52 pr-52">
      <h2 className="title text-center pb-5">
        Our <span className="text-green">Projects</span>
      </h2>
      {/* Project 1-3 */}
      <ul className="grid grid-cols-3 gap-8">
        {allProjectsData2.slice(0, 3).map((project) => {
          return (
            <li
              key={project.id}
              className="all-project-list border-2 border-gray-light "
            >
              <div className="project-image-cap">
                {project.coverProject.map((img) => (
                  <img
                    src={img.projectImage}
                    alt=""
                    className="bg-cover h-56 w-full"
                  />
                ))}
              </div>
              <div className="information-project">
                <h2 className="p-3">{project.title}</h2>
                <p className="font-light p-3 pb-6">{project.decription}</p>
              </div>
            </li>
          );
        })}
      </ul>
      {/* project 5 */}
      <div className="single-project-list mt-16 mb-16">
        {allProjectsData2.slice(4, 5).map((project) => {
          return (
            <div className="flex space-x-9 border-2 border-gray-light items-center">
              <div className="img-box-project">
                {project.coverProject.map((img) => (
                  <img
                    src={img.projectImage}
                    alt=""
                    className="bg-cover h-56"
                  />
                ))}
              </div>
              <div className="project-single-info p-4">
                <h2 className="pb-3">{project.title}</h2>
                <p className="font-light">{project.decription}</p>
              </div>
            </div>
          );
        })}
      </div>
      {/* project fire */}
      <h2 className="font-medium text-lg text-center mb-3">Fire Departments</h2>
      <p className="font-light text-center mb-8">
        In 2011 SBGroup was awarded with 4 consecutive Fire department projects
        in deferent locations of Afghanistan for Afghan National Police (ANP),
        the requirement for all the 4 fire departments were same similar they
        included the design and construction of super structure, sub-Structures,
        still structures Road network and all the utilities for different number
        of personnel of ANPFDs.
      </p>
      <ul className="grid grid-cols-4 gap-8">
        {allProjectsData2.slice(6,10).map((project) => {
          return (
            <li
              key={project.id}
              className="all-project-list border-gray-light border-2"
            >
              <div className="project-image-cap">
                {project.coverProject.map((img) => (
                  <img
                    src={img.projectImage}
                    alt=""
                    className="bg-cover h-56 w-full"
                  />
                ))}
              </div>
              <h2 className="p-3">{project.title}</h2>
              <p className="font-light p-3 pb-4">{project.decription}</p>
            </li>
          );
        })}
      </ul>
      <div className="section-break"></div>
      {/* project 6-7 */}
      <ul className="grid grid-cols-3 gap-8">
        {allProjectsData2.slice(10,13).map((project) => {
          return (
            <li
              key={project.id}
              className="all-project-list border-gray-light border-2"
            >
              <div className="project-image-cap">
                {project.coverProject.map((img) => (
                  <img
                    src={img.projectImage}
                    alt=""
                    className="bg-cover h-56 w-full"
                  />
                ))}
              </div>
              <h2 className="p-3">{project.title}</h2>
              <p className="font-light p-3 pb-6">{project.decription}</p>
            </li>
          );
        })}
      </ul>
      {/* project 8 */}
      {/* <div className="single-project-list mt-16 mb-16">
        {allProjectsData.slice(7, 8).map((project) => {
          return (
            <div className="flex flex-row-reverse space-x-9 border-2 border-gray-light items-center">
              <div className="img-box-project">
                <img src={project.image} alt="" />
              </div>
              <div className="project-single-info p-4">
                <h2 className="pb-3">{project.title}</h2>
                <p className="font-light">{project.description}</p>
              </div>
            </div>
          );
        })}
      </div> */}
      {/* <ul className="single-project-list mt-16 mb-16">
        {allProjectsData.slice(10,13).map((project) => {
          return (
            <li
              key={project.id}
              className="all-project-list flex space-x-9 border-2 border-gray-light items-center"
            >
              <img src={project.image} alt="" />
              <h2 className="p-3">{project.title}</h2>
              <p className="font-light p-3 pb-6">{project.description}</p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};

export default Projects;
