import waterContentImg from '../../assets/Images/services/waterContentImg.jpg'
const RegionWeWork = () => {
  return (
    <div className='flex items-center justify-center'>
      <div className="region-we-work-content bg-water pt-12 pb-8 text-white">
        <h2 className="pl-24 mb-5 text-xl">Regions We Worked In</h2>
        <ul className="region-we-work-lists font-light">
          <li>
            <h2 className='text-2xl'>
              3 <span className="text-sm">Countries</span>
            </h2>
          </li>
          <li>
            <h2 className='text-2xl'>
              20+ <span className="text-sm">Projects</span>
            </h2>
          </li>
          <li>
            <h2 className='text-2xl'>
              100+<span className="text-sm">million Track record</span>
            </h2>
          </li>
        </ul>
      </div>
      <div className="region-we-work-img">
        <img src={waterContentImg} alt="" />
      </div>
    </div>
  );
};

export default RegionWeWork;
