import heroPic from "../../assets/Images/capability/HeroPic.jpg";
import toughProjectsData from "../../data/capability/toughProjects";
import allProjectsData2 from "../../data/projects/allProjectsData";
const MCapabilities = () => {
  return (
    <div className="overflow-x-hidden">
      {/* HERO */}
      <div>
        <img
          src={heroPic}
          alt="hero"
          className="min-h-[90vh] min-w-[100VW] object-cover overflow-x-hidden"
        />
        <div className="text-center text-lg font-medium mt-4">
          <h2 className="">
            DESIGN AND BUILD QUALITY PROJECTS IN CHALLENGING ENVIRONMENTS.
          </h2>
        </div>
      </div>
      {/* TOUGH PROJECTS */}
      <div>
        <div className="text-center mt-20">
          <h2 className="text-2xl mb-5">
            Our
            <span className="text-green" id="our-work-hash">
              Tough
            </span>
            Experiences
          </h2>
          <p className="font-light mb-4">
            Experience, Ingenuity, Resilience SBGroup has earned a reputation
            for never shying away from tough projects.
          </p>
        </div>
        <ul className="grid grid-cols-1 gap-6">
          {toughProjectsData.map((project) => {
            return (
              <li key={project.id} className="tough-project-list text-center">
                <div className="img-tough-project">
                  <img src={project.image} alt="" />
                </div>
                <div className="content-tough-project text-white p-4">
                  <h2 className=" mb-3">{project.title}</h2>
                  <p className="text-xs font-extralight">
                    {project.decription}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {/* OUR PROJECTS */}
      <div className="projects-mobile mt-10">
        <h2 className="title text-center pb-5">
          Our <span className="text-green">Projects</span>
        </h2>
        {/* Project 1-3 */}
        <ul className="grid grid-cols-1 gap-8">
          {allProjectsData2.map((project) => {
            return (
              <li
                key={project.id}
                className="all-project-list border-2 border-gray-light "
              >
                <div className="project-image-cap">
                  {project.coverProject.map((img) => (
                    <img
                      src={img.projectImage}
                      alt=""
                      className="bg-cover h-56 w-full"
                    />
                  ))}
                </div>
                <div className="information-project">
                  <h2 className="p-3">{project.title}</h2>
                  <p className="font-light p-3 pb-6">{project.decription}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MCapabilities;
