import { v4 as uuid } from "uuid";
import logisticPic from '../../assets/Images/services/logisticSolution.png'
import financialPic from '../../assets/Images/services/financialServices.png'
import supplyPic from '../../assets/Images/services/supplyChain.png'
const logisticData = [
  {
    id: uuid(),
    image: logisticPic,
    title: 'Logistics',
    description: 'SBGroup provides reliable inter-national consignment delivery and logistics services to anywhere you demand. No need to arrange multiple shippers to cover a long trip across the globe. It means that whatever your source or destination is, SBGroup is there for you.'
  },
  {
    id: uuid(),
    image: supplyPic,
    title: 'Supply chain',
    description: 'SBGroup has supply chain focal points in more than 10 locations across the globe. Those focal points are selected strategically to cover our clients’ expectations.'
  },
  {
    id: uuid(),
    image: financialPic,
    title: 'Financial solution',
    description: 'Our track record shows that we are in a very good position of saving your finances, understanding to fair price from our focal points. This gives us an upper hand on our competitors in the local market.'
  },
]

export default logisticData