import React from "react";
import toughProjectsData from "../../data/capability/toughProjects";

const ToughProjects = () => {
  return (
    <div className="pl-52 pr-52">
      <div className="tough-title text-center mb-10">
        <h2 className="title">
          Our <span className="text-green" id="our-work-hash">Tough </span>Experiences
        </h2>
        <p className="font-light">
          Experience, Ingenuity, Resilience SBGroup has earned a reputation for
          never shying away from tough projects.
        </p>
      </div>
      <ul className="grid grid-cols-3 gap-12">
        {toughProjectsData.map((project) => {
          return (
            <li key={project.id} className="tough-project-list text-center">
              <div className="img-tough-project">
                <img src={project.image} alt="" />
              </div>
              <div className="content-tough-project text-white p-4">
                <h2 className=" mb-3">{project.title}</h2>
                <p className="text-xs font-extralight">{project.decription}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ToughProjects;
