import heroPic from "../../assets/Images/capability/HeroPic.jpg";
const HeroCapability = () => {
  return (
    <div className="hero-capability">
      <div className="hero-image">
        <img src={heroPic} alt="" />
      </div>
      <div className="hero-gradient"></div>
      <div className="hero-text">
        <h2 className="leading-32">
          DESIGN AND BUILD QUALITY PROJECTS IN CHALLENGING ENVIRONMENTS.
        </h2>
      </div>
    </div>
  );
};

export default HeroCapability;
