import constructionImg from "../../assets/Images/services/constructionImg.jpg";
const Construction = () => {
  return (
    <div className="construction-container flex items-center mt-52">
      <div className="construction-content pl-24 mr-24">
        <h2 className="title mb-5">
          Our Work <span className="title-red">Capability</span>
        </h2>
        <p className="font-light">
          Our capability is to provide a 'cradle to grave' construction service
          advising on all aspects of the process, including procurement, risk
          assessment and management during the project. We also help resolve
          disputes if they arise. We act for a wide range of clients, including
          contractors, owners, operators, and consultants. We have worked on
          some of the largest and most technically complex projects in
          Afghanistan.
        </p>
      </div>
      <img src={constructionImg} alt="" className="construction-img" />
    </div>
  );
};

export default Construction;
