import heroPic from "../../assets/Images/services/services_Hero.jpg";
const HeroServices = () => {
  return (
    <div className="hero-services">
      <img src={heroPic} alt="" />
      <h2 className="title-white">OUR SERVICES</h2>
    </div>
  );
};

export default HeroServices;
