import k9Pic from "../../assets/Images/services/k9.jpg";

const K9 = () => {
  return (
    <div className="flex flex-col items-center text-center pl-52 pr-52">
      <h2 className="title mb-5" id="hash-K9-service">
        LucasK9 Working dogs for <span className="title-red">K9 units</span>
      </h2>
      <p className="font-light">
        Since 2004 our partner LucasK9 have been professionally dealing with
        police dogs performance. who spent most of 2005 observing different
        systems of training dogs searching for explosives and drugs in American
        seminars. Training dogs let us better understand the law enforcement
        canine need. They had the opportunity and pleasure to work and train
        dogs for American police units.
      </p>
      <div className="k9-offers flex space-x-16 text-left mt-12 items-center">
        <img src={k9Pic} alt="k9 dog pic" />
        <div className="k9-content">
          <h2 className="text-medium text-2xl">Our services</h2>
          <p className="mt-4 leading-6">
            These beautiful, great dogs are carefully selected and pre-trained
            by Lucas K9, whereas the full training of majority of them is
            completed by local K9 centers and their trainers
            <br />
            <br />
            We would like to thank all the trainers of our dogs. We do
            appreciate great effort and passion they put into preparing our dogs
            to become valuable assets to K9 police units.
          </p>
          <div className="pt-7">
            <a
              href="https://lucask9.com/home"
              target={"_blank"}
              rel="noreferrer"
              className="text-green"
            >
              {" "}
              Visit LucasK9 website
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default K9;
