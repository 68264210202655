import ceoImg from "../../assets/Images/about/ceo-img.jpg";

const CeoMessage = () => {
  return (
    <div className="flex items-end">
      <div className="ceo-picture pl-24">
        <img src={ceoImg} alt="" />
      </div>
      <div className="ceo-message mb-3">
        <h2 className="font-medium text-lg mb-3">Sayed Ishaq Sadath</h2>
        <p className="mb-5 text-green font-light">Founder & CEO</p>
        <p className="font-light">
          We are committed to deliver quality service that meets the
          expectations of our valued clients. SBGroup delivers projects that
          demand more from the contractor than average. We are hard working,
          easy to talk to, and we love challenges.
        </p>
        <br />
        <span className="mt-2 font-light">
          <p>
            Mr Sadath is also president od Sadath Foundation Inc., a charity
            established to work in emergency relief, in the water sector, and in
            the disability services sector
          </p>
          <p className="mt-2">contact via: </p>
          <a className="text-green" href="mailto:foundation@sadatbg.com">
            foundation@sadatbg.com
          </a>
        </span>
      </div>
    </div>
  );
};

export default CeoMessage;
