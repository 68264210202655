import { v4 as uuid } from "uuid"
import DrTimPic from '../../assets/Images/about/DrTIm.jpg'
import ZahidPic from '../../assets/Images/about/Zahid-img.jpg'
import bashirPic from '../../assets/Images/about/bashir-img.jpg'
const leadershipMembers = [
  {
    id: uuid(),
    image: ZahidPic,
    name: 'Sayed Zahid Sadath',
    position: 'Vice President'
  },
  {
    id: uuid(),
    image: bashirPic,
    name: 'Bashir Ahmad Momand',
    position: 'General Manager'
  },
  {
    id: uuid(),
    image: DrTimPic,
    name: 'Dr Timothy Ives Harrold',
    position: 'Director - Water Sector'
  },
]

export default leadershipMembers