import figureImage from "../../assets/Images/homePage/chooseFigure.jpg";
const ChooseUs = () => {
  return (
    <div
      className="choose-us flex justify-between space-x-5 pt-24"
      id="hash-experince-footer"
    >
      <div className="choose-figure">
        <img
          src={figureImage}
          alt="choose figure"
          className="chooseFigureImg"
        />
        <div className="choose-figure-data grid grid-cols-2 grid-rows-2 gap-16">
          <div className="figures-num">
            <h2>200+</h2>
            <p>Employees</p>
          </div>
          <div className="figures-num">
            <h2>20+</h2>
            <p>Projects</p>
          </div>
          <div className="figures-num">
            <h2>15+</h2>
            <p>Years</p>
          </div>
          <div className="figures-num">
            <h2>100+</h2>
            <p>Million Track Record</p>
          </div>
        </div>
      </div>
      <div className="choose-info">
        <h2 className="title mb-5">
          Why <span className="title-red">Choose </span>Us?
        </h2>
        <p className="font-light  leading-6 mb-6">
          Here at SBGroup we pride customer relations the services in every
          initial planning build and construction. Above all, we want to work
          for you.
          <br />
          <br /> So why choose us:
        </p>
        <div className="bulletpoints space-y-4">
          <div className="font-light">
            • Because we’ve been there and done it. When you choose us, you’ll
            feel the benefit of 15+ years’ experience of build & constructions.
          </div>
          <div className="font-light">
            • Because we’re easy to work with. We take the work seriously, but
            not ourselves. We’re not prickly, precious or pretentious.
          </div>
          <div className="font-light">
            • Because we focus on results. For us, it’s all about what adds
            value for you and your business. Above all, we want our words to
            work for you.
          </div>
        </div>
        {/* <button className="btn-green mt-7">Read More</button> */}
      </div>
    </div>
  );
};

export default ChooseUs;
