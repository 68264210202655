import { v4 as uuid } from "uuid"
// import Hero from '../../assets/Images/homePage/Hero.jpg'
import Hero1 from '../../assets/Images/homePage/Hero-3.jpg'
const heroImages = [
  {
    id: uuid(),
    image: Hero1
  },
  // {
  //   id: uuid(),
  //   image: Hero
  // },
  // {
  //   id: uuid(),
  //   image: Hero
  // },
]

export default heroImages