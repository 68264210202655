import renewableImg from "../../assets/Images/services/renewableMain.jpg";

const RenewableEnergy = () => {
  return (
    <div className=" pl-24 pr-24" id="hash-eco">
      <h2 className="title text-center mb-10 ">
        Renewable <span className="title-red">Energy</span>
      </h2>
      <div className="renewable-energy-container flex  items-center justify-evenly space-x-5">
        <div className="renewable-img-box">
          <img src={renewableImg} alt="" />
        </div>
        <div className="renewable-content-box">
          <h2 className="text-2xl mb-5 font-light leading-8">
            <span className="text-green">Green</span> Energy <br /> For Everyone
          </h2>
          <p className="font-light">
            From planning to fulfilment of solar systems, SBGroup  offer
            competent customer service. The highest quality of individual
            components, especially solar modules, is at the forefront of our
            business. The type of solar module must be chosen to suit specific
            circumstances - decisive factors include the load capacity of the
            roof, wind load, snow load regions, the size of the roof surface,
            the roof pitch and the desired efficiency. In addition, SBGroup 
            places great importance to ensure that every solar module delivers
            with its efficiency and longevity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RenewableEnergy;
