import DNavbar from "./DNavbar"
import MNavbar from "./MNavbar"
import '../../../style/navbar.css'
const Navbar = () => {
  return (
    <div>
      <DNavbar/>
      <MNavbar/>
    </div>
  )
}

export default Navbar