import galleryBannerPic from "../../assets/Images/Gallery/banner.jpg";

const GalleryBanner = () => {
  return (
    <div className="gallery-banner">
      <img src={galleryBannerPic} alt=" gallery banner" />
      <div className="gallery-content text-center text-white">
        <h2 className="text-3xl font-bold">OUR GALLERY</h2>
      </div>
    </div>
  );
};

export default GalleryBanner;
