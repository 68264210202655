import recruitmentPics from "../../data/servicesData/recruitmentPics";
import recruitmentPic from "../../assets/Images/services/recruitmentPic.png";
const Recruitment = () => {
  return (
    <div className="mt-32  pl-24 pr-24 flex-col " id="hash-recruitment">
      <div className="title-section text-center mb-24">
        <h2 className="title">
          Recruitment & <span className="text-green">Training</span>
        </h2>
        <p className="font-light pl-20 pr-20 pt-5">
          There is always room to grow in your organization. SBGroup will assist
          you to determine which training will benefit your unique needs and
          situation. We can also help you have a well-designed recruitment
          process that increases job satisfaction, reduces wastage of resources,
          achieves better preservation of employees, and advances output. We can
          advise on best-practice selection process, touchable goals,
          implementation of interviewing practices and monitoring the results of
          your recruitment efforts.
        </p>
      </div>

      <div className="content-img-section flex items-center justify-center space-x-16">
        <p className="font-light">
          Functioning recruitment and employee selection ensures that the right
          candidates are placed in the right job, but it is not always an easy
          process. Having a well-designed recruitment process helps you increase
          job satisfaction, reduce wastage of resources, achieve better
          preservation of employees and advances output.
        </p>
        <img src={recruitmentPic} alt="" />
      </div>

      <div className="team-example pl-32 pr-32 mt-16">
        <p className="font-light text-justify">
          SBGroup will provide you all the critical recruiting skills like how
          to design a best-practice selection process, touchable goals,
          implementation of new staff, interviewing practices and monitoring the
          results of your recruitment efforts.
        </p>
        <ul className="grid grid-cols-3 gap-5 mt-10">
          {recruitmentPics.map((pic) => {
            return (
              <li className="recruitment-pic-list" key={pic.id}>
                <img src={pic.image} alt="" />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Recruitment;
