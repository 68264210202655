import motivatedPic from "../../assets/Images/homePage/motivatedPic.jpg";
import jarfPic1 from "../../assets/Images/homePage/jarfPic1.jpg";
import jarfPic2 from "../../assets/Images/homePage/jarfPic2.JPG";
import jarfPic3 from "../../assets/Images/homePage/jarfPic3.JPG";
const Motivated = () => {
  return (
    <div className="flex pl-24 justify-center items-center space-x-16 mt-32">
      <div className="motivated-content">
        <h2 className="title mb-5">
          Motivated to Solve 
          <span className="title-red"> Complex Problems</span>
        </h2>
        <p className="font-light">
          SB Group designed and constructed facilities to support the
          Afghanistan Border Police in Badakhshan Province. The site was
          extremely remote. SBGroup accepted the challenge and established a
          supply chain to the project site. There was no access road or bridge
          to deliver our material and equipment. SBGroup hired boats and barges
          and accessed the site via Tajikistan, including arranging all
          diplomatic permissions. We could only supply while the Amu river’s
          flow was low.
        </p>
      </div>
      <div className="motivated-image grid grid-cols-2 gap-4">
        <div className="pic1">
          <img src={jarfPic2} alt="" />
        </div>
        <div className="pic1">
          <img src={jarfPic1} alt="" />
        </div>
        <div className="pic1">
          <img src={motivatedPic} alt="" />
        </div>
        <div className="pic1">
          <img src={jarfPic3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Motivated;
