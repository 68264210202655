import { HashLink } from "react-router-hash-link";
const HeroContent = () => {
  return (
    <div id="hash-service-footer">
      <div className="content-box flex pt-24 pl-24 pr-24 justify-center items-center space-x-9">
        <div className="services-hashlinks font-light text-gray flex-col flex leading-9">
          <HashLink to={"/services#text"} className="text-green font-medium">
            Our services
          </HashLink>
          <HashLink to={"/services#hash-construction"}>Construction</HashLink>
          <HashLink to={"/services#hash-water"}>Water section</HashLink>
          <HashLink to={"/services#hash-eco"}>Renewable energy</HashLink>
          <HashLink to={"/services#hash-recruitment"}>Recruitment and trainings</HashLink>
          <HashLink to={"/services#hash-procurement"}>Procurement and supply</HashLink>
          <HashLink to={"/services#hash-logistics"}>Logistics</HashLink>
          <HashLink to={"/services#hash-testing"}>Test laboratory</HashLink>
          <HashLink to={"/services#hash-K9-service"}>K9 Services</HashLink>
        </div>
        <p className="font-light leading-7 hero-service-paragraph text-justify">
          SBGroup offers a full range of professional engineering services
          tailored to the needs of developers. Our project teams are skilled in
          disciplines ranging from building, highways, bridge & culvert, water
          supply structures, drainage, water resources and infrastructure. Our
          services include planning, environmental impact assessment, site
          appraisals, feasibility studies, concept layout, survey, architectural
          services, construction, refurbishment, structural integrity checking,
          and failure investigation. We can accomplish any kind of vertical
          structural such as Housing, Hospitals, Offices, Factories and
          warehousing, Car parks, Schools, Military facilities, Masts and
          towers, Leisure facilities, tunnels, culverts and subways, roads,
          drainage and flood control structures, bridges, earth retaining
          structures, and water retaining structures. Our Design-Build approach
          provides a unique value proposition to our clients, ensuring the
          seamless integration of design and construction activities. We bring
          top value to our clients through keeping the focus on the project's
          goals. <br /> <br /> Our proven 100+ million track record and our repeat clients are
          a testament to our expertise and high quality of work.
        </p>
      </div>
    </div>
  );
};

export default HeroContent;
