import galleryBannerPic from "../../assets/Images/Gallery/banner.jpg";
import Project1 from '../../components/gallery/projects/Project1'
import Project2 from '../../components/gallery/projects/Project2'
import Project3 from '../../components/gallery/projects/Project3'
import Project4 from '../../components/gallery/projects/Project4'
import Project5 from '../../components/gallery/projects/Project5'
import Project6 from '../../components/gallery/projects/Project6'
import Project7 from '../../components/gallery/projects/Project7'
import Project8 from '../../components/gallery/projects/Project8'
import Project9 from '../../components/gallery/projects/Project9'
import ProjectsGallery from "../../components/gallery/ProjectsGallery";

const MGallery = () => {
  return (
    // Hero
    <div className="mb-20">
      {/* Hero */}
      <div className="mb-24">
        <img
          src={galleryBannerPic}
          alt=" gallery banner"
          className="h-[40vh] object-cover object-bottom"
        />
        <h2 className="text-center text-white text-3xl font-bold -mt-12">
          OUR GALLERY
        </h2>
      </div>
      {/* Project Gallery */}
      <ProjectsGallery/>
      <Project1 />
      <Project2/>
      <Project3/>
      <Project4/>
      <Project5/>
      <Project6/>
      <Project7/>
      <Project8/>
      <Project9/>
    </div>
  );
};

export default MGallery;
