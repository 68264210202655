import solarHeroPic from "../../assets/Images/services/solarHeroPic.jpg";
const RenewableProjects = () => {
  return (
    <div className="pl-40 pr-40">
      <h2 className="text-2xl font-medium">Our Renewable Projects</h2>
      <div className="renewable-projects-container grid grid-cols-2 mt-5 items-center  gap-9">
        <div className="renewable-projects-content">
          <h2 className="text-xl mb-7">
            Solar Hero <span className="font-medium">Afghanistan</span>
          </h2>
          <p className="font-light">
          Solar Hero Afghanistan was formed in 2020 with SBGroup being a major stakeholder. It was formed as a partnership with AE Solar Germany.
          </p>
        </div>
        <div className="renewable-projects-img">
          <img src={solarHeroPic} alt="solar hero" />
        </div>
      </div>
    </div>
  );
};

export default RenewableProjects;
