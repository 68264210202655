import { v4 as uuid } from "uuid"
import visionPic from '../../assets/Images/about/vision.png'
import goalPic from '../../assets/Images/about/goal.png'
import missionPic from '../../assets/Images/about/mission.png'
const ourMissionData = [
  {
    id: uuid(),
    title: 'Vision',
    img: visionPic,
    caption: 'We pledge to provide our clients with superior construction services and deliver these services in a professional, reliable, responsive, and friendly manner.'
  },
  {
    id: uuid(),
    title: 'Mission',
    img: missionPic,
    caption: 'We provide our clients with superior construction services and we deliver our services in a professional, reliable, responsive, and friendly manner. We implement a long-term relationship with our clients, based on safety, quality, timeliness, innovation, and value adding.'
  },
  {
    id: uuid(),
    title: 'Goal',
    img: goalPic,
    caption: 'at SBGroup, we want to develop further together efficiently and with a clear goal so that we can stable and name and reputation of the company in the industry for the benefit of all. In this, the aspects of safety at work and quality are an integral component of everything we do.'
  },
]

export default ourMissionData