import { v4 as uuid } from "uuid";
import toughProjectPic1 from "../../assets/Images/capability/tough1.jpg";
import toughProjectPic2 from "../../assets/Images/capability/tough2.jpg";
import toughProjectPic3 from "../../assets/Images/capability/tough3.JPG";
const toughProjectsData = [
  {
    id: uuid(),
    image: toughProjectPic1,
    title: "BP Company HQ (4 sites) at Nangarhar Province, Afghanistan.",
    decription: "This project was unique due to its complex design and a 20M tall water tower, introduced by SBGroup to the client. ",
  },

  {
    id: uuid(),
    image: toughProjectPic2,
    title: "Company HQ (Austere), Yawez, Shar-e-Bozorg, Badakhshan, Afghanistan.",
    decription: "The site was extremely remote, procurement and supply for this construction was a big challenge. ",
  },
  {
    id: uuid(),
    image: toughProjectPic3,
    title: "ANA Presidential Air Wing Relocation SHKIA.",
    decription: "This project constructed the largest PEB building in Afghanistan, fabricated in Afghanistan during the covid lockdown.",
  },
] 

export default toughProjectsData