import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
const MFooter = () => {
  let iconStyle = { color: "white", fontSize: "1.2em" };
  return (
    <div className="h-[100%] bg-black text-center">
      <div className="section-2 flex p-4 justify-evenly text-white text-left">
        <div className="link-section">
          <h2>Who We Are</h2>
          <ul>
            <HashLink to={"/about#about-us"}>
              <li>
                <h2>About us</h2>
              </li>
            </HashLink>
            <HashLink to={"/about#our-leadership"}>
              <li>
                <h2>Our leadership</h2>
              </li>
            </HashLink>
            <HashLink to={"/#what-we-do"}>
              <li>
                <h2>What we do</h2>
              </li>
            </HashLink>
            <HashLink to={"/about#meet-team"}>
              <li>
                <h2>Meet the team</h2>
              </li>
            </HashLink>
          </ul>
        </div>
        <div className="link-section">
          <h2>Projects</h2>
          <ul>
            <HashLink to={"/services#hash-service-footer"}>
              <li>
                <h2>Our services</h2>
              </li>
            </HashLink>
            <HashLink to={"/#hash-experince-footer"}>
              <li>
                <h2>Our Experiences</h2>
              </li>
            </HashLink>
            <HashLink to={"/capabilities#our-work-hash"}>
              <li>
                <h2>Capabilities</h2>
              </li>
            </HashLink>
            <HashLink to={"/services#hash-water"}>
              <li>
                <h2>Water crisis</h2>
              </li>
            </HashLink>
          </ul>
        </div>
      </div>
      <div className="social-media-section text-white flex-col items-center flex mb-5">
        <h2 className="text-lg mb-4">Our Social Media Links</h2>
        <div className="social-icons flex space-x-4">
          <a
            href="https://m.facebook.com/profile.php?id=100063718116264"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF style={iconStyle} />
          </a>
          <a
            href="https://www.linkedin.com/in/sadat-bg-99590911a/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin style={iconStyle} />
          </a>
          <a
            href="https://twitter.com/SADATBUSINESSG"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter style={iconStyle} />
          </a>
          <a
            href="https://www.instagram.com/sadathbusinessgroupptyltd/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram style={iconStyle} />
          </a>
        </div>
      </div>
      <h2 className="text-white text-center pb-5">@SBG website</h2>
    </div>
  );
};

export default MFooter;
