import offers from "../../data/homeData/Offer";

const Offer = () => {
  return (
    <div className="home-offers p-24">
      <h2 className="title mb-5">What <span className="title-red">We</span> Offer?</h2>
      <ul className="offer-lists grid grid-cols-3 grid-rows-1">
        {offers.map((offer) => {
          return(
          <li key={offer.id}>
            <img src={offer.image} alt="offer pic" />
            <h2 className="font-medium mb-4">{offer.title}</h2>
            <p className="font-light text-gray-dark">{offer.description}</p>
          </li>
          )
        })}
      </ul>
    </div>
  );
};

export default Offer;
