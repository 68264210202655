import PEBpic from "../../assets/Images/services/PEB.jpg";

const PEB = () => {
  return (
    <div className=" peb-container pl-52 pr-52 flex">
      <div className="peb-content">
        <h2 className="title mb-3">
          Pre Engineered Steel Buildings{" "}
          <span className="title-red">(PEB)</span>
        </h2>
        <p>
          Design, manufacture and installation of all kinds of heavy metal
          industries (KSPAN and PEMB) air force work shops, Hangar and the
          following works:
        </p>
        <br />
        <p>
          1: Construction and installation of all types of industrial hangrs,
          warehouses, sports gymnasiums, metal skeletons for all types of tall
          houses. <br />
          PEB Residential or office connections including insulation, air
          conditioning, electrical, and plumbing upon request. <br />
          2: Roof top cranes. <br />
          3: Metal bridges, towers for electricity and telecommunications
          systems.
          <br />
          4: High capacity fuel tanks and storage. <br />
          5: Metal doors and windows and Roll up Doors
        </p>
      </div>
      <img src={PEBpic} alt="" />
    </div>
  );
};

export default PEB;
