import { HashLink } from "react-router-hash-link";
import heroImages from "../../data/homeData/HeroImages";
// import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa'
const Hero = () => {
  // const [current, setCurrent] = useState(0)
  // const length = slides.length
  return (
    <div className="home-hero">
      <div className="gradient-hero">
        <div className="red-box"></div>
        <div className="hero-title-box">
          <h2 className="hero-title font-bold">
            FROM CONCEPT TO <br />
            CREATION
          </h2>
          <p className="hero-paragraph font-light">
          We have a wide range of diverse experience in the world's most remote areas, with capacity and capability to operate in Central Asia, Australasia, Eastern Europe and Africa.
          </p>
          <HashLink to={'/capabilities#our-work-hash'}>
          <button className="btn-green">OUR WORK</button>
          </HashLink>
        </div>
        {heroImages.map((img) => {
          return (
            <img
              src={img.image}
              alt="Hero-Pic"
              key={img.id}
              className="image"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Hero;
