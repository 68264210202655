import MFooter from './MFooter'
import '../../../style/footer.css'
import DFooter from "./DFooter"

const Footer = () => {
  return (
    <div>
      <div className="hidden min-[800px]:block">
      <DFooter/>
      </div>
      <div className="md:hidden">
      <MFooter/>
      </div>
    </div>
  )
}

export default Footer