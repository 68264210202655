
import React from 'react'
import HeroCapability from '../../components/capabilities/HeroCapability'
// import SaysAboutUs from '../../components/capabilities/SaysAboutUs'
import ToughProjects from '../../components/capabilities/ToughProjects'
import Projects from '../../components/capabilities/Projects'

function DCapabilities() {
  return (
    <div>
      <HeroCapability/>
      <div className="section-break"></div>
      <ToughProjects/>
      <div className="section-break"></div>
      {/* <SaysAboutUs/>
      <div className="section-break"></div> */}
      <Projects/>
      <div className="section-break"></div>
    </div>
  )
}

export default DCapabilities