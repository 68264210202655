import { v4 as uuid } from "uuid";
import designPNG from '../../assets/png/HomePNG/design.png'
import constructionPNG from '../../assets/png/HomePNG/construction.png'
import logisticPNG from '../../assets/png/HomePNG/logistics.png'
import consultancyPNG from '../../assets/png/HomePNG/consultancy.png'
import renewablePNG from '../../assets/png/HomePNG/renewable.png'
import capacityPNG from '../../assets/png/HomePNG/capacity.png'
const offers = [
  {
    id: uuid(),
    image: designPNG,
    title: 'Design and Build',
    description: 'Our design team works closely with clients to discuss design requirements, evaluate details, and add value. our projects included structural planning, ground engineering, electrical, drainage & sewerage, vehicular access, parking, roads full documentation, operation and maintenance manuals, training, and handover.'
  },
  {
    id: uuid(),
    image: constructionPNG,
    title: 'Water and Energy',
    description: 'SBGroup works in renewable energy, water security, and irrigation projects. We work with you to identify and implement best practice solutions and implement them.'
  },
  {
    id: uuid(),
    image: logisticPNG,
    title: 'Logistics',
    description: 'SBGroup’s experienced procurement officers provide logistic support and supply of all kinds of materials and staff (building/construction materials, commodities, specialist support) on request. SBGroup has the capabilities of a large company and maintains a management team who get the job done.'
  },
  {
    id: uuid(),
    image: consultancyPNG,
    title: 'Construction Consultancy',
    description: 'SB Group’s talented team of Construction Economists, Quantity Surveyors, and Project Managers work with you to achieve successful project outcomes.'
  },
  {
    id: uuid(),
    image: renewablePNG,
    title: 'Environment Assessment',
    description: 'The SBGroup technical team conducting the Environmental Assessment will identify, evaluate and mitigate the biophysical, social, and environmental impacts of development proposals. We have capacity to conduct both pre and post assessments to measure project impacts.'
  },
  {
    id: uuid(),
    image: capacityPNG,
    title: 'Capacity Building',
    description: 'SBGroup’s talented team of Construction Economists, Quantity Surveyors, and Project Managers work with you to achieve successful project outcomes.'
  },
]

export default offers