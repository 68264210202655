import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import SuccessMsg from "./SuccessMsg";

const Form = () => {
  const form = useRef();
  const [result, showResult] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rfkc138",
        "template_ynkr67c",
        form.current,
        "XcMVQR3fjas5FNrB_"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  return (
    <>
      {result ? (
        <SuccessMsg />
      ) : (
        <div className="flex flex-col pl-96 pr-96 mb-9">
          <div className="form-title text-center mb-10 text-2xl font-medium">
            <h2>Get in touch</h2>
          </div>
          <div className="form-box rounded-lg p-8 pl-16 pr-16">
            <form
              onSubmit={onSubmit}
              className="contactUsForm p-16 pt-8 pb-8"
              ref={form}
            >
              {/* Name */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Name">Name</label>
                <input
                  type="text"
                  name="user_name"
                  id="name"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Company Name */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="CompanyName">Company Name</label>
                <input
                  type="text"
                  name="user_company"
                  id="company-name"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Phone */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="PhoneNumber">Phone Number</label>
                <input
                  type="text"
                  name="user_phone"
                  id="phone-number"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Email */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Email">Email</label>
                <input
                  type="email"
                  name="user_email"
                  id="email"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Message */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Message">Message</label>
                <textarea
                  placeholder="Please type your message here..."
                  name="user_message"
                  id="message"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>

              <div className="button-form p-3 bg-black text-center text-white">
                <button type="submit" value={"send"} className=" p-1">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
