import workedWithData from "../../data/about/workedWith";

const WorkedWith = () => {
  return (
    <div className="pl-32 pr-32 mt-24 text-center">
      <h2 className="title pt-8 mb-4">
        Organizations <span className="title-red">We Worked</span> With{" "}
      </h2>
      <p className="font-light text-gray mb-12">
        We worked with more than 10+ organizations some of them are mentioned
        below
      </p>
      <ul className="grid grid-cols-4 grid-row-2 gap-24 max-[900px]:grid-cols-2 ">
        {workedWithData.map((data) => {
          return (
            <li
              key={data.id}
              className="worked-with-list flex items-center justify-center"
            >
              <a href={data.link} target='_blank' rel="noreferrer">
                <img src={data.logo} alt="data logo" />
              </a>
              <div className="worked-with-description">
                {/* <p className='invisible'>{data.description}</p> */}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WorkedWith;
