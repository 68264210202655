import { v4 as uuid } from "uuid";

const officesData = [
  {
    id: uuid(),
    country: 'Australia',
    address: 'Waterman Business Centre Suite 19B, Level 1, 64 Victor Crescent Narre Warren 3805 Australia',
    contact: '+61 401 332 886',
    email: 'sadatbg@yahoo.com',
    map: 'https://www.google.com/maps/place/Waterman+Narre+Warren/@-38.0172867,145.3131535,14.66z/data=!3m1!5s0x6ad611408343097b:0xcf2011008536613c!4m19!1m13!4m12!1m4!2m2!1d28.9826024!2d41.0312065!4e1!1m6!1m2!1s0x6ad617f888705293:0x5502ba43e04f86b0!2sWaterman+Business+Centre+Suite+19B,+Level+1,+64+Victor+Crescent+Narre+Warren+3805+Australia!2m2!1d145.3134765!2d-38.0229148!3m4!1s0x6ad617f888705293:0x5502ba43e04f86b0!8m2!3d-38.0229148!4d145.3134765'
  },
  {
    id: uuid(),
    country: 'Afghanistan',
    address: 'Wazir Akbar khan, Kabul, Afghanistan.',
    contact: '+93 202 313 965',
    email: 'sadatbg@yahoo.com',
    map: 'https://www.google.com/maps/place/Wazir+Akbar+Khan,+Kabul,+Afghanistan/@34.5357711,69.1758977,15z/data=!4m5!3m4!1s0x38d16ea5b997864b:0x928ec03caa07c42c!8m2!3d34.5363261!4d69.183451'
  },
]

export default officesData