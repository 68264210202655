import leadershipMembers from "../../data/team/leaderships";
const Leadership = () => {
  return (
    <div className="flex-col text-center mt-10">
      <h2 className="title mb-5">
        Our <span className="title-red" id="our-team">Leadership</span> team
      </h2>
      {/* <p className="font-light">We are committed, Hard working, easy to Talk and we love challenges</p> */}
      <div className="leaders-box">
        <ul className="flex items-center justify-center p-24 space-x-24">
          {leadershipMembers.map((member) => {
            return (
              <li
                key={member.id}
                className="leader-list flex-col flex items-center"
              >
                <img src={member.image} alt="leader pic" />
                <h2 className="text-lg">{member.name}</h2>
                <p className="text-sm font-light">{member.position}</p>
              </li>
            );
          })}
        </ul>
        <div className="background-grad"></div>
      </div>
    </div>
  );
};

export default Leadership;
