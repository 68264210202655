import heroBanner from "../../assets/Images/contact/HeroBanner.jpg";
import officesData from "../../data/contact/offices";
import { FaLocationArrow, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import SuccessMsg from "../../components/contact/SuccessMsg";

const MContact = () => {
  const form = useRef();
  const [result, showResult] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rfkc138",
        "template_ynkr67c",
        form.current,
        "XcMVQR3fjas5FNrB_"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  return (
    <div className="overflow-x-hiiden min-h-screen">
      {/* Hero */}
      <div className="flex-col justify-between items-center">
        <div className="banner-img">
          <img src={heroBanner} alt="" />
        </div>
        <div className="p-4">
          <h2 className="title mb-2">
            Our <span className="text-green">Offices</span>
          </h2>
          <p className="font-light">
            Please feel free to contact us by sending us your feedback through
            filling the form or simply dial our phone no. And we will get back
            to you as soon as possible.
          </p>
          <ul className="office-box grid grid-cols-1 gap-4 mt-10">
            {officesData.map((office) => {
              return (
                <li key={office.id}>
                  <h2 className="text-xl font-medium mb-3">{office.country}</h2>
                  <div className="contact-box flex justify-start space-x-6 mb-3">
                    <FaLocationArrow size={"1rem"} />
                    <a
                      href={office.map}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-gray"
                    >
                      <h2 className="font-light text-sm">{office.address}</h2>
                    </a>
                  </div>
                  {/* phone */}
                  <div className="contact-box flex justify-start space-x-7 mb-3 ">
                    <FaPhoneAlt size={"1rem"} />
                    <a
                      href={"tel:" + office.contact}
                      className="hover:text-gray"
                    >
                      <h2 className="font-light text-sm">{office.contact}</h2>
                    </a>
                  </div>
                  {/* email */}
                  <div className="contact-box flex justify-start space-x-7">
                    <FaEnvelope size={"1rem"} />
                    <a
                      href={"mailto:" + office.email}
                      className="hover:text-gray"
                    >
                      <h2 className="font-light text-sm">{office.email}</h2>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* Form */}
      {result ? (
        <SuccessMsg />
      ) : (
        <div className="flex flex-col mb-9 p-2">
          <div className="form-title text-center mb-10 text-2xl font-medium">
            <h2>Get in touch</h2>
          </div>
          <div className="form-box rounded-lg p-2">
            <form
              onSubmit={onSubmit}
              className="contactUsForm p-4 pt-8 pb-8"
              ref={form}
            >
              {/* Name */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Name">Name</label>
                <input
                  type="text"
                  name="user_name"
                  id="name"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Company Name */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="CompanyName">Company Name</label>
                <input
                  type="text"
                  name="user_company"
                  id="company-name"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Phone */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="PhoneNumber">Phone Number</label>
                <input
                  type="text"
                  name="user_phone"
                  id="phone-number"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Email */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Email">Email</label>
                <input
                  type="email"
                  name="user_email"
                  id="email"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>
              {/* Message */}
              <div className="input-field flex flex-col space-y-2 mb-9 font-light">
                <label htmlFor="Message">Message</label>
                <textarea
                  placeholder="Please type your message here..."
                  name="user_message"
                  id="message"
                  className="text-input border border-gray-light p-2 rounded-sm"
                  required
                />
              </div>

              <div className="button-form p-3 bg-black text-center text-white">
                <button type="submit" value={"send"} className=" p-1">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MContact;
