import DrTimPic from "../../assets/Images/services/DrTim.jpg";
const DrTimBio = () => {
  return (
    <div className="flex pl-32 pr-32 space-x-8 items-center">
      <div className="tim-image">
        <img src={DrTimPic} alt="Dr Tim" />
      </div>
      <div className="tim-bio">
        <h2 className="text-lg ">Dr. Tim Harrold</h2>
        <p className="text-green mb-5 font-light">Water Sector Advisor - Sadath Business Group</p>
        <p className="font-light">
          I am an Australian water engineer. For 14 years up to November 2019 I
          was planning engineer at Wannon Water in southwest Victoria Australia.
          I was lead author of Wannon Water’s Urban Water Strategy, a 50 year
          plan for water supply security for towns in southwest Victoria. Now I
          want to help provide drinking water for the people of Kabul. This is
          integrated with wastewater solutions in the context of the Kabul
          groundwater basin. <br /> <br /> The mission of SBGroup’s water sector is to help the
          development of Afghanistan’s water sector. Indirectly this will save
          lives as people will die if they do not have enough clean water. As
          sector director, my priorities are: Educational development of Afghan
          water managers; Positive personal relationships with funding agencies,
          government and government agencies, ngos and private industry; <br /> To
          develop new water projects in cooperation with other stakeholders.
        </p>
      </div>
    </div>
  );
};

export default DrTimBio;
