import "../../style/services.css";
import DServices from "./DServices";
import MServices from "./MServices";

const Services = () => {
  return (
    <div>
      <div className="desktop-size max-[]:">
        <DServices />
      </div>
      <div className="mobile-size md:invisible">
        <MServices />
      </div>
    </div>
  );
};

export default Services;
