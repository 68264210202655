import DGallery from './DGallery'
import '../../style/gallery.css'
import MGallery from './MGallery'
const Gallery = () => {
  return (
    <div>
      <div className="desktop-size">
      <DGallery/>
      </div>
      <div className="mobile-size md:invisible">
        <MGallery/>
      </div>
    </div>
  )
}

export default Gallery