import { v4 as uuid } from "uuid";
import soilPic from '../../assets/Images/services/soilTestPic.jpg'
import asphaltPic from '../../assets/Images/services/AsphaltPic.jpg'
import concretPic from '../../assets/Images/services/concretPic.jpg'
const materialTestData = [
  {
    id: uuid(),
    image: concretPic,
    title: 'Concrete Test',
    description: 'We test your every concrete at every stage. (ASTM C873), Rebound Hammer (ASTM C805), Penetration Resistance Test (ASTM C803), Ultrasonic Pulse Velocity (ASTM C597), Pullout Test (ASTM C900), Drilled Core (ASTM C42) '
  },
  {
    id: uuid(),
    image: asphaltPic,
    title: 'Asphalt Test',
    description: 'We offer you the asphalt tests for your Roads: Penetration numbers, Needle penetration, Cone penetration, Hardness, Ductility, Melting point Density Storage monitoring to assess oxidation state, Testing of drummed bitumen over time to asses batch lifetime.'
  },
  {
    id: uuid(),
    image: soilPic,
    title: 'Soil Test',
    description: 'Levels of soil tests SBGroup propose to you. geotechnical test, Soil reactivity, The load-carrying capacity of the soil, The density of the soil, Levels of moisture in the soil'
  },
]

export default materialTestData