import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About'
import Navbar from './shared/component/navbar/Navbar'
import Footer from './shared/component/footer/Footer'
import Services from './pages/services/Services';
// import UnderConstruction from './shared/component/other/UnderConstruction';
import Capabilities from './pages/capabilities/Capabilities';
// import Resposibility from './pages/responsibilty/Resposibility';
import Gallery from './pages/gallery/Gallery'
import Contact from './pages/contactUs/Contact'
function App() {
  return (
    <div className="App">
      <Router >
        <Navbar/>
        <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/capabilities' element={<Capabilities/>}/>
          {/* <Route path='/responsabilities' element={<Resposibility/>}/> */}
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
